import { render, staticRenderFns } from "./RsnChannelCommercial.vue?vue&type=template&id=202b51ac&scoped=true&"
import script from "./RsnChannelCommercial.vue?vue&type=script&lang=js&"
export * from "./RsnChannelCommercial.vue?vue&type=script&lang=js&"
import style0 from "./RsnChannelCommercial.vue?vue&type=style&index=0&id=202b51ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202b51ac",
  null
  
)

export default component.exports