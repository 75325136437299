<template>
  <v-chip outlined class="rounded-0 px-1 mx-2 action-link" :class="channelClass">
    {{ channel.number_display }}
    <template
      v-if="channel.number_display != channel.callsign_display"
    >
      -
      {{ channel.callsign_display }}
    </template>
  </v-chip>
</template>

<script>
export default {
  name: "Channel",
  props: {
    channel: Object,
    index: Number,
    lit: Boolean,
  },
  computed: {
    channelClass() {
      let baseClass ="";

      if (this.layout == 0) {
        baseClass += 'mt-2 ';
      }

      if (this.channel.dispute) {
        baseClass += this.lit ? "burgundy white--text" : "burgundy--text";
      } else if (this.channel.nondtv) {
        //Yes, not a DTV Channel
        baseClass += this.lit ? "warning" : "warning--text";
      } else {
        //No, not a DTV Channel
        baseClass += this.lit ? "blue white--text" : "blue--text";
      }
      return baseClass;
    },
    layout() {
      return this.$store.getters.getCurrentSelectedOptions("layout");
    },
  },
};
</script>

<style scoped>
.feed-text {
  font-size: 11px;
}
.feed-chip {
  padding-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  font-weight: bolder;
  font-size: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
}
.white-channel {
  border-color: white;
}
.black-channel {
  border-color: black;
}
</style>
