<template>
  <v-row class="d-none d-print-block">
    <v-col>
      <h3 class="text-center">DirecTV Channel Change Schedule</h3>
      <h5 class="text-center">For: {{ $parent.focus }}</h5>
      <div v-for="(group, group_index) in groups" :key="group.id">
        <div v-if="group.screens && group.screens.length">
          <v-card
            class="mb-1"
            outlined
            width="100%"
            v-for="(screen, screen_index) in group.screens"
            v-bind:key="screen.id"
          >
            <v-card-title>
              Group: {{ group.name }} - Screen: {{ screen.name }}
            </v-card-title>

            <v-simple-table
              dense
              v-if="
                (screen.preferred && screen.preferred.length) ||
                (screen.favorites && screen.favorites.length)
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Time</th>
                    <th class="text-left">Channel</th>
                    <th class="text-left">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="event in events(group_index, screen, screen_index)"
                    :key="event.id"
                  >
                    <td>{{ eventTime(event.start) }}</td>
                    <td>{{ event.channel }}</td>
                    <td>{{ event.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text v-else>
              No scheduled events for this screen today. Please go back and click some
              events for them to appear here.
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Print",
  computed: {
    groups() {
      return this.$store.getters.getGroups;
    },
    schedule() {
      return this.$store.getters.getSchedule;
    },
  },
  methods: {
    events(group_index, screen, screen_index) {
      var forced = [];

      var events = [];

      for (const broadcast_key in this.schedule) {
        let broadcast = this.schedule[broadcast_key];

        const first = moment.tz(broadcast.DateTime, "UTC"); //Start
        const second = first.clone().add(broadcast.Duration, "minutes"); //End

        //First we push preferred events
        if (
          screen.preferred &&
          screen.preferred.indexOf(broadcast.BroadcastID) >= 0
        ) {
          forced.push({
            id: broadcast.BroadcastID,
            name: broadcast.MatchupTitle,
            scheduleGroupTitle: broadcast.ScheduleGroupTitle,
            schedule: broadcast.ScheduleID,
            favorite: true,
            preferred: true,
            start: first.toDate(),
            end: second.toDate(),
            scheduleTitle: broadcast.ScheduleTitle,
            timed: true,
            category: screen.id.toString(),
            group_index,
            screen_index,
            channel: broadcast.ChannelNumberMajor
          });
          continue;
        }

        //Then we push favorite events
        if (
          screen.favorites &&
          screen.favorites.indexOf(broadcast.BroadcastID) >= 0
        ) {
          forced.push({
            id: broadcast.BroadcastID,
            name: broadcast.MatchupTitle,
            scheduleGroupTitle: broadcast.ScheduleGroupTitle,
            schedule: broadcast.ScheduleID,
            favorite: true,
            preferred: false,
            start: first.toDate(),
            end: second.toDate(),
            scheduleTitle: broadcast.ScheduleTitle,
            timed: true,
            category: screen.id.toString(),
            group_index,
            screen_index,
            channel: broadcast.ChannelNumberMajor
          });
          continue;
        }
      }

      events.sort((a, b) => a.start.getTime() - b.start.getTime());

      //Update times!
      for (const event_index in forced) {
        //Ingore first event
        if (event_index == 0) {
          continue;
        }

        var prev_event = forced[event_index - 1];
        var cur_event = forced[event_index];

        let range1 = moment.range(prev_event.start, prev_event.end);
        let range2 = moment.range(cur_event.start, cur_event.end);
        if (range1.overlaps(range2)) {
          //YES OVERLAP, NEEDS TIME CHANGE
          //If both preferred or first preferred, or neither are preferred, current event start is end of previous
          if (
              (prev_event.preferred && cur_event.preferred) || 
              (prev_event.preferred && !cur_event.preferred) ||
              (!prev_event.preferred && !cur_event.preferred)
            ) {
                cur_event.start = prev_event.end;
          }
        }
      }

      events = forced;

      return events;
    },
    eventTime: function (eventDateTime) {
      let dateTime = moment(eventDateTime);
      return dateTime.format("h:mmA");
    },
  },
};
</script>