<template>
    <v-footer padless>
        <v-card
            flat
            tile
            width="100%"
            color="#102641"
            class="py-16"
            :class="$vuetify.breakpoint.mdAndDown
                    ? 'px-5 px-sm-8'
                    : `footer-${$vuetify.breakpoint.name}`
                "
        >
            <template v-if="false">
                <v-row
                    no-gutters
                    class="logo-padding"
                >
                    <v-col class="contain-paint">
                        <footer-logo />
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    class="mb-10"
                >
                    <v-col
                        class="mb-10"
                        cols="6"
                        sm="4"
                        lg="2"
                        v-for="category in footerLinks"
                        :key="category.title"
                    >
                        <div class="footer-title mb-7 mb-md-4">
                            {{ category.title }}
                        </div>
                        <div
                            class="mb-7 mb-md-4"
                            v-for="link in category.links"
                            :key="link.title"
                            link
                        >
                            <a
                                class="footer-link"
                                :href="link.url"
                            >{{ link.title }}</a>
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    class="mt-10"
                >
                    <v-col>
                        <a
                            class="footer-link"
                            href="tel:+18005315000"
                        >
                            Need help? Call us at 800.531.5000
                        </a>
                        <v-divider class="footer-divider mt-5 mb-6" />
                    </v-col>
                </v-row>
            </template>
            <v-row no-gutters>
                <v-col
                    cols="12"
                    md="auto"
                    class="bottom-padding"
                    v-for="(link, link_index) in bottomLinks"
                    :key="link.title"
                >
                    <v-row no-gutters>
                        <v-col
                            cols="12"
                            md="auto"
                        >
                            <a
                                :href="link.url"
                                class="footer-link"
                            >{{ link.title }}</a>
                        </v-col>
                        <v-col
                            cols="12"
                            md="auto"
                        >
                            <v-divider
                                v-if="link_index + 1 < bottomLinks.length"
                                class="footer-divider footer-links-divider-height mx-0 mx-md-3 my-6 my-md-1"
                                :vertical="$vuetify.breakpoint.mdAndUp"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                class="mt-5"
            >
                <v-col class="copyright">
                    ©{{ currentYear }} DIRECTV. DIRECTV and all other DIRECTV marks are trademarks of
                    DIRECTV, LLC. All other marks are the property of their respective
                    owners.
                </v-col>
            </v-row>
            <v-row
                no-gutters
                class="mt-10"
            >
                <v-col
                    cols="auto"
                    v-for="social in socials"
                    :key="social.title"
                >
                    <v-btn
                        icon
                        :href="social.url"
                        max-width="16"
                        class="mr-5"
                    >
                        <v-icon size="16">
                            ${{ social.icon }}
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

        </v-card>
    </v-footer>
</template>

<script>
import FooterLogo from "./FooterLogo";

export default {
    name: "DtvFooter",
    components: {
        FooterLogo,
    },
    data() {
        return {
            footerLinks: [
                {
                    title: "STREAM",
                    links: [
                        {
                            title: "Packages",
                            url: "https://www.directv.com/stream/packages/",
                        },
                        {
                            title: "Channel lineup",
                            url: "https://www.directv.com/stream/channel-lineup/",
                        },
                        {
                            title: "Sports packages",
                            url: "https://www.directv.com/stream/sports/",
                        },
                        {
                            title: "Technology",
                            url: "https://www.directv.com/stream/technology/",
                        },
                        {
                            title: "Spanish packages",
                            url: "https://www.directv.com/stream/spanish/",
                        },
                    ],
                },
                {
                    title: "SATELLITE",
                    links: [
                        {
                            title: "Packages",
                            url: "https://www.directv.com/satellite/packages/",
                        },
                        {
                            title: "Channel lineup",
                            url: "https://www.directv.com/satellite/channel-lineup/",
                        },
                        {
                            title: "Sports packages",
                            url: "https://www.directv.com/satellite/sports/",
                        },
                        {
                            title: "Technology",
                            url: "https://www.directv.com/satellite/technology/",
                        },
                        {
                            title: "Spanish packages",
                            url: "https://www.directv.com/satellite/spanish/",
                        },
                    ],
                },
                {
                    title: "Premium add-ons",
                    links: [
                        {
                            title: "HBO Max",
                            url: "https://www.directv.com/premium-channels/hbo-max/",
                        },
                        {
                            title: "Showtime",
                            url: "https://www.directv.com/premium-channels/showtime/",
                        },
                        {
                            title: "MGM+",
                            url: "https://www.directv.com/premium-channels/mgm-plus/",
                        },
                        {
                            title: "Starz",
                            url: "https://www.directv.com/premium-channels/starz/",
                        },
                        {
                            title: "Cinemax",
                            url: "https://www.directv.com/premium-channels/cinemax/",
                        },
                    ],
                },
                {
                    title: "Sports & international",
                    links: [
                        {
                            title: "NFL",
                            url: "https://www.directv.com/sports/nfl/",
                        },
                        {
                            title: "Sports schedules",
                            url: "https://sports.directv.com/all/all",
                        },
                        {
                            title: "International SATELLITE",
                            url: "https://www.directv.com/satellite/international/",
                        },
                        {
                            title: "International for STREAM",
                            url: "https://www.directv.com/stream/international/",
                        },
                    ],
                },
                {
                    title: "More options",
                    links: [
                        {
                            title: "DIRECTV for Business",
                            url: "https://www.directv.com/forbusiness/?source=IDdB000000000002L",
                        },
                        {
                            title: "Internet bundles",
                            url: "https://www.directv.com/internet-bundles/",
                        },
                        {
                            title: "Moving?",
                            url: "https://www.directv.com/moving/",
                        },
                        {
                            title: "DIRECTV in my area",
                            url: "https://www.directv.com/city/",
                        },
                        {
                            title: "DIRECTV App",
                            url: "https://www.directv.com/satellite/technology/app/",
                        },
                    ],
                },
                {
                    title: "Favorites",
                    links: [
                        {
                            title: "Account sign-in",
                            url: "https://oidc.idp.clogin.att.com/mga/sps/oauth/oauth20/authorize?response_type=id_token&client_id=m45917&scope=openid&response_mode=form_post&state=HALODTV&nonce=sdfdds8&redirect_uri=https%3A%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Doverview",
                        },
                        {
                            title: "Customer support",
                            url: "https://www.directv.com/support/satellite/",
                        },
                        {
                            title: "Insider entertainment",
                            url: "https://www.directv.com/insider",
                        },
                        {
                            title: "Watch DIRECTV Satellite",
                            url: "https://www.directv.com/guide",
                        },
                        {
                            title: "Watch DIRECTV STREAM",
                            url: "https://oidc.idp.clogin.att.com/mga/sps/oauth/oauth20/authorize?response_type=id_token&client_id=m45917&scope=openid&response_mode=form_post&state=HALODTV&nonce=sdfdds8&redirect_uri=https%3A%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dwatch",
                        },
                    ],
                },
            ],
            bottomLinks: [
                {
                    title: "About DIRECTV",
                    url: "https://www.directv.com/insider/corporate/",
                },
                {
                    title: "Careers",
                    url: "https://jobs.directv.com/",
                },
                {
                    title: "Legal policy center",
                    url: "https://www.directv.com/legal/legal-policy-center/",
                },
                {
                    title: "Privacy center",
                    url: "https://www.directv.com/privacy/privacy-center/",
                },
                {
                    title: "Your Privacy Choices",
                    url: "https://www.directv.com/privacy/choices/",
                },
                {
                    title: "Privacy notices",
                    url: "https://www.directv.com/privacy/directv-privacy-policies/",
                },
                {
                    title: "Site map",
                    url: "https://www.directv.com/sitemap/",
                },
                {
                    title: "FCC public files",
                    url: "https://www.directv.com/legal/fcc-public-inspection-for-directv-and-uverse/",
                },
                {
                    title: "Accessibility",
                    url: "https://www.directv.com/corporate/accessibility",
                },
                {
                    title: "Contact Us",
                    url: "https://www.directv.com/customer-service/",
                }
            ],
            socials: [
                {
                    title: "LinkedIn",
                    url: "https://www.linkedin.com/showcase/directvforbusiness/",
                    icon: "linkedin",
                },
                // {
                //     title: "Facebook",
                //     url: "https://www.facebook.com/directv",
                //     icon: "facebook",
                // },
                {
                    title: "Twitter",
                    url: "https://twitter.com/directvbusiness",
                    icon: "twitter",
                },
                // {
                //     title: "Instagram",
                //     url: "https://www.instagram.com/directv",
                //     icon: "instagram",
                // },
                {
                    title: "YouTube",
                    url: "https://www.youtube.com/user/DIRECTV",
                    icon: "youtube",
                },
                // {
                //     title: "TikTok",
                //     url: "https://www.tiktok.com/@directv",
                //     icon: "tiktok",
                // },
            ],
        };
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
};
</script>

<style lang="scss" scoped>
.logo-padding {
    padding-bottom: 80px;
}

.footer-title {
    color: rgb(153, 153, 153) !important;
    font-size: 14px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.3em !important;
    letter-spacing: -0.02em !important;
    -webkit-font-smoothing: antialiased;
}

.footer-link {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: -0.02em;
    color: rgb(254, 254, 254);
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}

.footer-xl {
    padding-left: 180px;
    padding-right: 180px;
}

.footer-lg {
    padding-left: 140px;
    padding-right: 140px;
}

.footer-divider {
    background-color: #fefefe;
    opacity: 0.3;
}

.footer-links-divider-height {
    height: 8px !important;
    min-height: unset !important;
}

.bottom-padding {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.copyright {
    font-size: 16px !important;
    color: #999;
    line-height: 18px;
    font-family: "Roboto", sans-serif !important;
}
</style>
