<template>
    <v-row
        no-gutters
        justify="center"
    >
        <v-col cols="auto">
            <v-btn
                color="warning"
                :href="sportsBarUrl"
                target="_blank"
                class="black--text"
            >
                <v-icon
                    left
                    dark
                >
                    mdi-map-search
                </v-icon>
                WATCH AT A BAR
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Sportsbar",
    props: {
        game: Object,
    },
    computed: {
        currentZip() {
            return this.$store.getters.getCurrentSelectedOptions("zip");
        },
        sportsBarUrl() {
            let url = `https://sportsbarfinder.com/`;
            if (this.currentZip && this.currentZip.length > 4) {
                url += `?zip=${this.currentZip}`;
            }
            return url;
        },
    },
};
</script>
