<template>
    <div>
        <v-card>
            <v-list
                flat
                v-if="Object.keys(options).length > 0"
            >
                <v-list-item-group>
                    <v-list-item
                        v-bind:key="option.key"
                        v-for="option in options"
                        @click="$emit('add-option', option)"
                    >
                        <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-card-text v-else class="text-center">
                <p>No more options to add.</p>
                <v-btn color="blue darken-1" text @click="$emit('done')">
                    Close
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "AddCustomizationModal",
    props: {
        settings: Array,
    },
    computed: {
        options() {
            let screenOptions = this.$store.getters.getScreenOptions;
            //Make a new object by looping through the object properties and make a new object that does not have the same keys as settings
            let options = Object.keys(screenOptions).reduce((obj, key) => {
                if (!this.settings || !this.settings.some(setting => setting.key === key)) {
                    obj[key] = screenOptions[key];
                }
                return obj;
            }, {});
            return options;

        },
    },
};
</script>
