<template>
  <v-container fluid class="pa-0">
    <v-row justify="space-around" no-gutters>
      <v-col cols="12" sm="10" md="8" lg="7">
        <div class="text-center mt-4 text-h6" v-if="!result">
          To find out what your local regional sports networks are and which pro
          teams can be viewed on those channels please enter your ZIP Code.
        </div>
      </v-col>
    </v-row>
    <h5 v-if="error" class="red--text text-center mt-4">
      {{ error }}
    </h5>
    <v-row v-if="result" justify="space-around" class="mt-5">
      <v-col cols="12" sm="10" md="8" lg="7" xl="6">
        <div class="text-center mt-4 text-h6">
          The {{ zip }} ZIP Code is in the home team territory for the following
          pro teams. With HD Access select channels and teams available in HD
          are noted below:
        </div>
      </v-col>
    </v-row>
    <v-row justify="space-around" class="mt-5">
      <v-col
        :cols="$vuetify.breakpoint.width < 350 ? 12 : 10"
        sm="5"
        md="4"
        lg="3"
      >
        <v-text-field
          v-mask="mask"
          :rules="rules"
          :loading="loading"
          solo
          class="rounded-pill"
          color="black"
          label="Zip code"
          append-icon="fas fa-search"
          v-model="zip"
          @click:append="getInfo"
          @change="getInfo"
          @input="rate = null"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="result" justify="center">
      <v-col cols="auto">
        <v-row no-gutters style="max-width: 1220px" justify="center">
          <rsn-channel
            :channel="channel"
            v-for="channel in result"
            :key="channel.id"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-10">
      <v-col cols="auto">
        <a href="https://www.directv.com/packages/" target="_blank" class="not-a-customer-link">Not a DIRECTV customer? Learn more about our packages.</a>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
  <script>
import { mask } from "vue-the-mask";
import ApiService from "../api/api.service";
import RsnChannel from "../components/Rsinfo/RsnChannel";

export default {
  name: "Rsinfo",
  directives: {
    mask,
  },
  components: {
    RsnChannel,
  },
  data() {
    return {
      loading: false,
      zip: null,
      error: null,
      result: null,
      mask: "#####-####",
      rules: [
        (v) =>
          /^0|[0-9]{5}(?:-[0-9]{4})?|null$/.test(v) ||
          "Please enter a valid zip code",
      ],
    };
  },
  computed: {},
  methods: {
    getInfo() {
      this.error = null;
      this.result = null;
      this.loading = true;
      ApiService.post("/rsinfo", { zip: this.zip })
        .then(({ data }) => {
          if (data.message && data.message.length > 0) {
            this.error = data.message;
          }
          this.result = data.channels;
          this.loading = false;
        })
        .catch(({ response }) => {
          this.error = response.message;
          this.loading = false;
        });
      return;
    },
    reset() {
      this.error = null;
      this.result = null;
    },
  },
  mounted() {
    //this.getInfo();
  },
};
</script>
  
<style scoped>
.mt-17 {
  margin-top:68px;
}
.dtv-title {
  font-size: 2.5em;
}
.v-text-field >>> .v-label {
  font-size: 1.5em;
}

.v-text-field >>> input {
  font-size: 1.5em;
  padding: 40px 30px;
  max-height: 85px;
}
::v-deep .v-input__slot {
  padding: 0 30px !important;
}
.grey--background {
  background-color: #fbfbfb;
}
.not-a-customer-link {
  font-size: 0.875em
}
</style>