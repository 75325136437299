<template>
  <v-row no-gutters justify="center">
    <v-col cols="12">
      <v-simple-table>
        <tr v-if="isMobile">
          <td>&nbsp;</td>
          <td class="text-center">SPREAD</td>
          <td class="text-center">MONEY</td>
          <td class="text-center">O/U</td>
        </tr>
        <tr v-else>
          <th>&nbsp;</th>
          <th class="text-center">SPREAD</th>
          <th class="text-center">MONEY</th>
          <th class="text-center">O/U</th>
        </tr>
        <tr>
          <td>
            <v-img
                class="d-print-none  contain-paint mx-auto"
                contain
                height="30"
                max-width="45"
                :src="appUrl + '/img/logos/teams/' + (game.away_team.master_team_id ? game.away_team.master_team_id : game.away_team.id) + '.svg'"
                :alt="game.away_team.name + ' Logo'"
            />
          </td>
          <td class="text-center">
            <div v-if="game.favorite_team_id == game.away_team_id">
              {{ forcePlus(parseFloat(game.favorite_points)) }}
              <br v-if="!isMobile" />
              <span class="primary--text">{{
                forcePlus(Math.round(game.favorite_money))
              }}</span>
            </div>
            <div v-else>
              {{ forcePlus(parseFloat(game.favorite_points * -1)) }}
              <br v-if="!isMobile" />
              <span class="primary--text">{{
                forcePlus(Math.round(game.underdog_money))
              }}</span>
            </div>
          </td>
          <td class="text-center">
            {{ forcePlus(game.away_money) }}
          </td>
          <td class="text-center">
            <div>
              O {{ parseFloat(game.total_points) }}
              <br v-if="!isMobile" />
              <span class="primary--text">{{
                forcePlus(Math.round(game.over_money))
              }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <v-img
              class="d-print-none  contain-paint mx-auto"
              contain
              height="30"
              max-width="45"
              :src="appUrl + '/img/logos/teams/' + (game.home_team.master_team_id ? game.home_team.master_team_id : game.home_team.id) + '.svg'"
              :alt="game.home_team.name + ' Logo'"
            />
          </td>
          <td class="text-center">
            <div v-if="game.favorite_team_id == game.home_team_id">
              {{ forcePlus(parseFloat(game.favorite_points)) }}
              <br v-if="!isMobile" />
              <span class="primary--text">{{
                forcePlus(Math.round(game.favorite_money))
              }}</span>
            </div>
            <div v-else>
              {{ forcePlus(parseFloat(game.favorite_points * -1)) }}
              <br v-if="!isMobile" />
              <span class="primary--text">{{
                forcePlus(Math.round(game.underdog_money))
              }}</span>
            </div>
          </td>
          <td class="text-center">
            {{ forcePlus(game.home_money) }}
          </td>
          <td class="text-center">
            U {{ parseFloat(game.total_points) }}
            <br v-if="!isMobile" />
            <span class="primary--text">{{
              forcePlus(Math.round(game.under_money))
            }}</span>
          </td>
        </tr>
      </v-simple-table>
    </v-col>
    <v-col cols="12" class="text-center">
      <v-divider class="thick-border" v-if="!isMobile" />
      <div>
        BETTING ODDS POWERED BY
        <a
          href="https://stats.com"
          target="_blank"
          class="font-weight-bold text-decoration-none"
          >STATS.COM</a
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BettingOdds",
  props: {
    game: Object,
  },
};
</script>

<style lang="scss" scoped>
td, th {
  vertical-align: middle;
  min-width: 80px;
  font-size: 1.1em;
  border-bottom: 2px solid rgba(0,0,0,.12); /* Add grey border to the bottom */
  border-right: 2px solid rgba(0,0,0,.12); /* Add grey border to the right */
}

/* Remove the right border from the last cell of each row */
tr td:last-child,
tr th:last-child {
  border-right: none;
}

/* Remove the bottom border from the last row */
tr:last-child td,
tr:last-child th {
  border-bottom: none;
}

.thick-border {
  border-bottom: 1.5px solid rgba(0,0,0,.12); /* Add grey border to the bottom */
}
</style>
