<template>
  <div
    style="overflow: hidden"
    v-dragscroll.x
  >
    <v-row class="d-none d-print-block">
      <v-col class="text-center">
        <div class="printed-date my-2">{{ printDate(selectedOptions) }}</div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col cols="auto">
        <!-- MOBILE -->
        <v-row
          v-if="isMobile"
          no-gutters
          justify="space-around"
          class="d-print-none mt-4"
          :style="embedRowStyle"
        >
          <v-col
            cols="auto"
            class="text-center action-link mx-0 mx-sm-1"
            @click="updated(selectable_option.value)"
            v-for="(
              selectable_option
            ) in options"
            :key="selectable_option.name"
          >
            <v-hover
              class="ma-auto"
              v-slot:default="{ hover }"
            >
              <v-card
                elevation="0"
                flat
                tile
                min-height="42"
                min-width="48"
                class="d-flex pa-0"
                :style="embedCardStyle(selectable_option.value)"
              >
                <v-card-text
                  class="align-self-center pa-0 ma-sm-4"
                  :class="barTextClass(selectable_option.value, hover)"
                  :style="embedTextStyle(selectable_option.value)"
                >
                  <div class=" day">
                    {{ selectable_option.text }}
                  </div>
                  <div class="date">{{ selectable_option.subtext }}</div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <!-- DESKTOP -->
        <v-row
          v-else
          dense
          class="d-print-none my-2 date-row"
          :style="embedRowStyle"
        >
          <v-col
            class="pa-0 text-center action-link"
            @click="updated(selectable_option.value)"
            v-for="(
              selectable_option, selectable_option_index
            ) in option.options"
            :key="selectable_option.name"
          >
            <v-hover
              class="ma-auto"
              v-slot:default="{ hover }"
            >
              <v-card
                :elevation="hover ? 6 : 0"
                flat
                tile
                min-height="100"
                max-height="100"
                class="d-flex pa-0"
                :class="hover ? 'higher-z-index' : ''"
                :style="embedCardStyle(selectable_option.value)"
              >
                <v-card-text
                  class="align-self-center"
                  :class="barTextClass(selectable_option.value, hover)"
                  :style="embedTextStyle(selectable_option.value)"
                >
                  <div class="font-weight-bold day-md">
                    {{ selectable_option.text }}
                  </div>
                  <div class="date-md pt-2">{{ selectable_option.subtext }}</div>
                </v-card-text>
                <v-divider
                  v-if="!hover && showDivider(selectable_option.value,selectable_option_index)"
                  vertical
                  color="lightGrey"
                  class="date-divider ma-auto"
                />
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { POST_SELECTED_OPTION, GET_GAMES, GET_EMBED_GAMES } from "../../../store/actions.type";

export default {
  name: "DateBar",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    option() {
      return this.$store.getters.getSingleOption("days");
    },
    options() {
      //Hide the last two days when under 400px
      if (this.$vuetify.breakpoint.width < 400) {
        return this.option.options.slice(0, -2);
      }
      return this.option.options;
    },
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
    embed() {
      return this.$store.getters.getCurrentEmbed;
    },
    embedRowStyle() {
      let style = {};

      if (!this.isEmbed) {
        return style;
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        style = {
          border: `8px solid #${this.embed.customization.primary}`,
          borderRadius: "4px",
        };
      } else {
        style = {
          border: `4px solid #${this.embed.customization.primary}`,
          borderRadius: "2px",
        };
      }


      return style;
    }
  },
  methods: {
    printDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    updated(options) {
      this.loading = true;
      let key = this.option.key;

    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: options,
    //   });

      this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
        this.loading = false;

        // this.$gtag.event("api", {
        //   event_category: "options",
        //   event_label: "get_events",
        // });

        //Get Embed Games
        if(this.isEmbed) {
          this.$store.dispatch(GET_EMBED_GAMES, this.$route.params.groupuid).then(() => {
            this.$emit("done");
          }).catch((e) => {
            this.$emit("done");
          });
          return;
        }

        this.$store.dispatch(GET_GAMES);
      });
    },
    barTextClass(option, hover) {
      let text_class = "";

      text_class =
        this.selectedOptions == option
          ? "blue--text"
          : "darkGray--text text--lighten-1";

      if (this.isEmbed) {
        text_class = null;
      }

      return text_class;
    },
    embedCardStyle(option) {
      let style = {};

      if (!this.isEmbed) {
        return style;
      }

      if (this.selectedOptions == option) {
        style = {
          backgroundImage: `url(${this.appUrl}/uploads/thumb/${this.embed.customization.accent_image})`,
        };
      }


      return style;
    },
    embedTextStyle(option) {
      let style = {};

      if (!this.isEmbed) {
        return style;
      }

      if (this.selectedOptions == option) {
        style = {
          color: `#${this.embed.customization.secondary}`,
        };
      } else {
        style = {
          color: `#${this.embed.customization.alternative}`,
        };
      }


      return style;
    },
    showDivider(selectedOption, index) {
      if (this.isEmbed && this.selectedOptions == selectedOption) {
        return false;
      }

      if (index == this.option.options.length - 1) {
        return false;
      }

      return true;
    }
  },
};
</script>


<style scoped>.day {
  white-space: nowrap;
}

.date {
  font-size: 0.8em !important;
  white-space: nowrap;
}

.printed-date {
  font-size: 1.5em;
}

.day-md {
  font-size: 1.8em !important;
}

.date-md {
  font-size: 1.2em !important;
}

.date-row {
  width: 1002px;
  border: 8px solid var(--directv-light-darkGray);
}

.scroll-row {
  overflow: hidden;
}

.date-divider {
  height: 28px;
  border-width: 1px;
}
.higher-z-index {
  z-index: 1000;
}


</style>
