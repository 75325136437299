import { render, staticRenderFns } from "./MobileSports.vue?vue&type=template&id=f982abd8&scoped=true&"
import script from "./MobileSports.vue?vue&type=script&lang=js&"
export * from "./MobileSports.vue?vue&type=script&lang=js&"
import style0 from "./MobileSports.vue?vue&type=style&index=0&id=f982abd8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f982abd8",
  null
  
)

export default component.exports