<template>
  <v-card>
    <v-card-title>Customize Your Embed</v-card-title>
    <loading v-if="loading" />
    <v-row
      class="mx-4"
      v-else
    >
      <v-col
        cols="12"
        sm="6"
      >
        <h3>Primary</h3>
        <v-color-picker
          light
          flat
          hide-mode-switch
          :show-swatches="false"
          v-model="localCustomization.primary"
        ></v-color-picker>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <h3>Secondary</h3>
        <v-color-picker
          light
          flat
          hide-mode-switch
          :show-swatches="false"
          v-model="localCustomization.secondary"
        ></v-color-picker>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <h3>Alternative</h3>
        <v-color-picker
          light
          flat
          hide-mode-switch
          :show-swatches="false"
          v-model="localCustomization.alternative"
        ></v-color-picker>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <h3>Accent Image</h3>
        <upload-image @done="imageUploaded" />
        <v-img
          :src="`${appUrl}/uploads/show/${localCustomization.accent_image}`"
          class="fill-height ma-0"
          align="center"
          justify="center"
          max-height="200"
          max-width="200"
          contain
          alt="Accent Image Preview"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

      </v-col>
    </v-row>
    <v-card-actions>

      <v-btn
        color="primary"
        @click="$emit('done')"
      >
        Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { GET_CUSTOMIZATION, POST_CUSTOMIZATION } from '../../store/actions.type';

import UploadImage from './UploadImage';
import Loading from '../shared/Loading';

export default {
  name: "EmbedModal",
  components: {
    Loading,
    UploadImage,
  },
  data() {
    return {
      loading: true,
      image: null,
      localCustomization: null,
    };
  },
  computed: {
    customization() {
      return this.$store.getters.getCurrentCustomization;
    },
  },
  methods: {
    imageUploaded(hash) {
      this.localCustomization.accent_image = hash;
    },
    save() {
      this.$store.dispatch(POST_CUSTOMIZATION, this.localCustomization).then(() => {
        this.$emit('done');
      });
    }
  },
  mounted() {
    this.$store.dispatch(GET_CUSTOMIZATION).then(() => {
      this.localCustomization = this.customization;
      this.loading = false;
    });
  },
  watch: {
    localCustomization: {
      handler(value) {
        if (value.primary.toString().match(/#[a-zA-Z0-9]{8}/)) {
          this.localCustomization.primary = value.primary.substr(0, 7);
        }
        if (value.secondary.toString().match(/#[a-zA-Z0-9]{8}/)) {
          this.localCustomization.secondary = value.secondary.substr(0, 7);
        }
        if (value.alternative.toString().match(/#[a-zA-Z0-9]{8}/)) {
          this.localCustomization.alternative = value.alternative.substr(0, 7);
        }
      },
      deep: true,
    },
  }
};
</script>
