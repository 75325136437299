<template>
    <div
        class="lightGray"
        :class="isMobile ? ' fill-height' : ''"
    >
        <dtv-header
            class="d-print-none"
            v-if="!isFramed"
        />

        <v-main
            class="no-print-padding"
        >
            <v-container
                :fluid="$vuetify.breakpoint.lgAndDown"
                class="px-2 px-sm-4 px-lg-8 px-xl-12"
            >
                <v-col class="text-center mt-4" v-if="!isMobile">
                    <h1 class="sport-header font-weight-bold">
                        {{ leagueNameDisplay }}
                        <template v-if="hasTeam">
                            - {{ teamNameDisplay.toUpperCase() }}
                        </template>
                        {{ $route.meta.title }}
                    </h1>
                </v-col>

                <v-row
                    v-if="isMobile"
                    justify="center"
                    align="center"
                >
                    <v-col cols="auto">
                        <a :href="`${appUrl}/leagues/${$route.params.league.toUpperCase()}/schedule`">
                            <v-img
                                class="contain-paint"
                                contain
                                :src="`${appUrl}/img/logos/leagues/${$route.params.league.toLowerCase()}.svg`"
                                width="80"
                                height="80"
                                :alt="$route.params.league.toUpperCase() + ' Logo'"
                            />
                        </a>
                    </v-col>
                    <v-col cols="auto">
                        <h1 class="text-center">
                            {{ leagueNameDisplay }}
                            <template v-if="$route.params.team && $route.params.team !== '0'">
                                - {{ teamNameDisplay.toUpperCase() }}
                            </template>
                            {{ $route.meta.title }}
                        </h1>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="d-print-none">
                    <v-col cols="auto">
                        <breadcrumb />
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="d-print-none"
                    :class="!isMobile ? 'my-4' : ''"
                >
                    <v-col cols="auto">
                        <a :href="`${appUrl}/leagues/${$route.params.league.toUpperCase()}/schedule`">

                            <v-img
                                v-if="!isMobile"
                                class="contain-paint"
                                contain
                                :src="`${appUrl}/img/logos/leagues/${$route.params.league.toLowerCase()}.svg`"
                                width="80"
                                height="80"
                                :alt="$route.params.league.toUpperCase() + ' Logo'"
                            />
                        </a>
                    </v-col>
                    <v-col cols="auto">
                        <v-tabs
                            centered
                            background-color="transparent"
                            class="mx-sm-2"
                            color="grey darken-1"
                            show-arrows
                        >
                            <v-tab
                                class="font-weight-bold"
                                v-for="link in filteredLinks"
                                :to="detailLink(link)"
                                :key="link.title"
                            >
                                {{ link.title }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <router-view />

            </v-container>
        </v-main>

    </div>
</template>

<script>
import DtvHeader from "../../components/shared/DtvHeader";
import LogoBar from "../Home/LogoBar";
import Breadcrumb from "../../components/shared/Breadcrumb";

export default {
    name: "Base",

    components: {
        DtvHeader,
        Breadcrumb,
        LogoBar,
    },
    data() {
        return {
            links: [
                {
                    "title": "Schedule",
                    "for": [
                        "leagues",
                        "teams"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl",
                        "mls"
                    ]
                },
                {
                    "title": "Teams",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl",
                        "mls"
                    ]
                },
                {
                    "title": "Standings",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl",
                        "mls"
                    ]
                },
                {
                    "title": "Players",
                    "for": [
                        "leagues",
                        "teams"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl",
                        "mls"
                    ]
                },
                {
                    "title": "Leaders",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mls"
                    ]
                },
                // {
                //     "title": "Team Leaders",
                //     "leagues": [
                //         "mls"
                //     ]
                // },
                {
                    "title": "Coaches",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl",
                        "mls"
                    ]
                },
                // {
                //     "title": "Team Rankings",
                //     "leagues": [
                //         "mls"
                //     ]
                // },
                {
                    "title": "Depth Charts",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl",
                        "nba",
                        "mlb",
                        "nhl"
                    ]
                },
                {
                    "title": "Fantasy Projections",
                    "for": [
                        "leagues"
                    ],
                    "leagues": [
                        "nfl"
                    ]
                },
                // {
                //     "title": "Transactions",
                //     "leagues": [
                //         "mlb"
                //     ]
                // },
                // {
                //     "title": "Injuries",
                //     "leagues": [
                //         "mlb"
                //     ]
                // },
                // {
                //     "title": "Matchup Gaming",
                //     "leagues": [
                //         "mlb"
                //     ]
                // }
            ],

        };
    },
    computed: {
        filteredLinks() {
            if (this.$route.params.team && this.$route.params.team !== '0') {
                return this.links.filter(link => link.for.includes('teams') && link.leagues.includes(this.$route.params.league.toLowerCase()));
            } else {
                return this.links.filter(link => link.for.includes('leagues') && link.leagues.includes(this.$route.params.league.toLowerCase()));
            }
        },
        leagueNameDisplay() {
            //If the league param contains NCAA, put a space after NCAA
            let league = this.$route.params.league;

            if (league.includes("NCAA")) {
                league = league.replace("NCAA", "NCAA ");
            }

            return league.toUpperCase();
        },
        teamNameDisplay() {
            //Get team name from parameter
            var team = this.$route.params.team;

            // Remove dashes and numbers
            team = team.replace(/-/g, " ").replace(/\d+$/, "");

            //Force Title Case
            team = this.toTitleCase(team);

            return team;
        },

        hasTeam() {
            return this.$route.params.team && this.$route.params.team !== "0";
        },
    },
    methods: {
        detailLink(link) {
            if (this.hasTeam) {
                return `/leagues/${this.$route.params.league}/teams/${this.$route.params.team}/${this.makeLink(link)}`;
            } else {
                return `/leagues/${this.$route.params.league}/${this.makeLink(link)}`;
            }
        },
        makeLink(link) {
            //Replace space with - and make lower case.
            return link.title.toLowerCase().replace(/ /g, "-");
        },
    },
};
</script>


<style scoped>
.sport-header {
    font-size: 1.6rem !important;
    letter-spacing: 0.02em !important;
}

@media print {
    .no-print-padding {
        padding: 0 !important;
    }
}

.gradient-background-flipped {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(244, 244, 244, 1) 70px,
            rgba(244, 244, 244, 1) 100%);
}
</style>
