<template>
    <v-card class="game-details-container">
        <v-row
            class="group-header px-1"
            align="center"
            no-gutters
        >

            <v-col class="white--text">
                <h2 class="text-uppercase group-header-text text-center my-4">SEE EXCLUSIVE GAMES AT</h2>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            v-for="(location, index) in limitedLocations"
            :key="index"
        >
            <v-col class="game-details">

                <v-row no-gutters>
                    <v-col class="text-center box-shadow py-2">

                        <v-img
                            class="contain-paint"
                            contain
                            :src="`/img/logos/customers/${location.logo}`"
                            width="100%"
                            height="150"
                        />
                        <div class="text-center pb-0 py-1 mt-2">
                            <h3>{{ location.name }}</h3>
                            {{ location.address }}
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="text-center">
                        <v-btn
                            class="ma-2"
                            color="primary"
                            dark
                            href="location.url"
                        >
                            Visit Website
                        </v-btn>
                    </v-col>
                </v-row>









            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "CustomerLocations",
    data() {
        return {
            locations: [
                {
                    id: 1,
                    name: "New York",
                    address: "123 Main St",
                    city: "Anytown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 2,
                    name: "Location 2",
                    address: "456 Elm St",
                    city: "Othertown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 3,
                    name: "Location 3",
                    address: "789 Oak St",
                    city: "Thistown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 4,
                    name: "Location 4",
                    address: "1011 Pine St",
                    city: "ThatTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 5,
                    name: "Location 5",
                    address: "1213 Cedar St",
                    city: "ThisTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 6,
                    name: "Location 6",
                    address: "1415 Maple St",
                    city: "ThatTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 7,
                    name: "Location 7",
                    address: "1617 Oak St",
                    city: "ThisTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 8,
                    name: "Location 8",
                    address: "1819 Pine St",
                    city: "ThatTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 9,
                    name: "Location 9",
                    address: "2021 Cedar St",
                    city: "ThisTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                },
                {
                    id: 10,
                    name: "Location 10",
                    address: "2223 Maple St",
                    city: "ThatTown",
                    state: "CA",
                    zip: "12345",
                    phone: "123-456-7890",
                    logo: "twinpeaks.svg",
                    url: "https://twinpeaksrestaurant.com/locations/fargo"
                }
            ]
        };
    },
    computed: {
        limitedLocations() {
            return this.locations.slice(0, 1);

            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return this.locations.slice(0, 1);
                case "sm":
                    return this.locations.slice(0, 2);
                case "md":
                    return this.locations.slice(0, 3);
                case "lg":
                    return this.locations.slice(0, 4);
                case "xl":
                    return this.locations.slice(0, 5);
            }
        }
    }
};

</script>

<style scoped>
.group-header {
    width: 408px;
    background: rgb(51, 122, 206);

}

.group-header-text {
    font-size: 1.6em !important;
    font-weight: 900;
    letter-spacing: 0em !important;
}

.game-details-container {
    width: 408px;
}

.box-shadow {
    box-shadow:
        inset 0 8px 8px -8px rgba(0, 0, 0, 0.8),
        /* Top inset shadow */
        inset 0 -8px 8px -8px rgba(0, 0, 0, 0.8)
        /* Bottom inset shadow */
    ;

}
</style>
