<template>
  <v-row class="d-none d-print-block">
    <v-col>
      <h3 class="text-center">DirecTV Channel Change Planner</h3>
      <h5 class="text-center">For: {{ $parent.focus }}</h5>
      <div v-for="(group, group_index) in groups" :key="group.id">
        <div v-if="group.screens && group.screens.length">
          <v-card
            class="mb-1"
            outlined
            width="100%"
            v-for="(screen, screen_index) in group.screens"
            v-bind:key="screen.id"
          >
            <v-card-title>
              Group: {{ group.name }} - Screen: {{ screen.name }}
            </v-card-title>

            <v-simple-table
              dense
              v-if="
                (screen.preferred && screen.preferred.length) ||
                (screen.favorites && screen.favorites.length)
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Time</th>
                    <th class="text-left">Channel</th>
                    <th class="text-left">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="game in screenGames(group_index, screen, screen_index)"
                    :key="game.id"
                  >
                    <td>{{ eventTime(game.start_at) }}</td>
                    <td>{{ game.channel }}</td>
                    <td>{{ game.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text v-else>
              No scheduled events for this screen today. Please go back and click some
              events for them to appear here.
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Print",
  computed: {
    groups() {
      return this.$store.getters.getGroups;
    },
    schedule() {
      return this.$store.getters.getSchedule;
    },
  },
  methods: {
    screenGames(group_index, screen, screen_index) {
      const forcedPreferred = [];
      const forcedFavorite = [];
      const regular = [];

      var screenGames = [];


      this.allGames.forEach((allGame) => {

        const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
        const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

        //First we push preferred games
        if (
          screen.preferred &&
          screen.preferred.indexOf(allGame.id) >= 0
        ) {
          forcedPreferred.push(this.getCalendarFormattedGame(allGame, false, true, first, second, screen, group_index, screen_index));
          return;
        }

        //Then we push favorite games
        if (
          screen.favorites &&
          screen.favorites.indexOf(allGame.id) >= 0
        ) {
          forcedFavorite.push(this.getCalendarFormattedGame(allGame, true, false, first, second, screen, group_index, screen_index));
          return;
        }



        if (this.view == "all") {
          regular.push(this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index));
          return;
        }
      }); // End of first showing Loop (Forced and All)

      if (this.view == "schedule") {
        screenGames = forcedPreferred.concat(forcedFavorite);
        return screenGames;
      }

      //Make favorite games show up first, then regular
      if (this.view == "all") {
        screenGames = forcedPreferred.concat(forcedFavorite).concat(regular);
        return screenGames;
      }

      //Show suggested fill in
      if (this.view == "suggest") {
        this.allGames.forEach((allGame) => {

          const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
          const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End
          let ignore = false;

          //Ignore already preferred
          if (
            screen.preferred &&
            screen.preferred.indexOf(allGame.id) >= 0
          ) {
            return;
          }

          //Ignore already favorited
          if (
            screen.favorites &&
            screen.favorites.indexOf(allGame.id) >= 0
          ) {
            return;
          }

          //Check if conflicts with forced, ignore
          if (forcedPreferred.length) {

            forcedPreferred.forEach((forced_showing) => {
              const forced_start = moment(forced_showing.start); //Start
              const forced_end = moment(forced_showing.end); //End

              //Check overlap of forced games and ignore
              let range1 = moment.range(forced_start, forced_end);
              let range2 = moment.range(first, second);
              if (range1.overlaps(range2)) {
                ignore = true;
              }
            });

            if (ignore) return;
          }

          if (forcedFavorite.length) {
            forcedFavorite.forEach((forced_showing) => {
              const forced_start = moment(forced_showing.start); //Start
              const forced_end = moment(forced_showing.end); //End

              //Check overlap of forced games and ignore
              let range1 = moment.range(forced_start, forced_end);
              let range2 = moment.range(first, second);
              if (range1.overlaps(range2)) {
                ignore = true;
              }
            });

            if (ignore) return;
          }

          //Check if overlap of regular games and compare
          if (regular.length) {
            regular.forEach((regular_game, regular_index) => {
              const regular_start = moment(regular_game.start); //Start
              const regular_end = moment(regular_game.end); //End

              let range1 = moment.range(regular_start, regular_end);
              let range2 = moment.range(first, second);

              //If overlap, compare
              if (range1.overlaps(range2)) {
                var leaguesIndex = screen.settings
                  ? screen.settings.findIndex(
                    (setting) => setting.key === "leagues"
                  )
                  : null;
                //Does screen have leagues preferred?
                if (screen.settings && leaguesIndex >= 0) {

                  var gamePriority = screen.settings[leaguesIndex].selectedOptions.indexOf(allGame.league.id);
                  var regularPriority = screen.settings[leaguesIndex].selectedOptions.indexOf(regular_game.league_id);

                  if (
                    (regularPriority < 0 && gamePriority >= 0)
                    ||
                    (
                      gamePriority >= 0 &&
                      (gamePriority < regularPriority)
                    )
                  ) {
                    regular[regular_index] = this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index);
                    //Already pushed, so ignore rest
                    ignore = true;
                  } else {
                    ignore = true;
                  }
                } else {
                  //Overlaps for no reason, ignore
                  ignore = true;
                }
              }
            });
            if (ignore) return;
          }

          regular.push(
            this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
          );
          return;
        }); //End of suggested Loop

        screenGames = forcedPreferred.concat(forcedFavorite).concat(regular);
        screenGames.sort((a, b) => a.start.getTime() - b.start.getTime());

        //Fill in remaining gaps
        screenGames.some((listed_game, listed_game_index) => {

          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;

            console.log(gap_start, gap_end);


            //Fill with best possible match
            this.allGames.forEach((allGame) => {
              if (allGame.showings.length == 0) return;



              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added showings
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare
              if (game_range.overlaps(gap_range) && (first.isSameOrBefore(gap_start) && second.isSameOrAfter(gap_end))) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });
            return;
          };
        });

        //Check for any more gaps
        screenGames.some((listed_game, listed_game_index) => {
          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;

            //Fill with best possible match
            this.allGames.forEach((allGame) => {
              if (allGame.showings.length == 0) return;

              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added games
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC");  //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare, less restrictive
              if (game_range.overlaps(gap_range)) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });

          }
        });

        //Last for any more gaps
        screenGames.some((listed_game, listed_game_index) => {
          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;


            //Fill with best possible match
            this.allGames.forEach((allGame) => {

              if (allGame.showings.length == 0) return;

              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added showings
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare, less restrictive
              if (game_range.overlaps(gap_range) && (second.isSameOrAfter(gap_end))) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });
          }
        });



        return screenGames;
      }
    },
    eventTime: function (eventDateTime) {
      let dateTime = moment(eventDateTime);
      return dateTime.format("h:mmA");
    },
  },
};
</script>
