import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_GROUPS,
    POST_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP,
    POST_SCREEN,
    UPDATE_SCREEN,
    DELETE_SCREEN,
    GET_SCREEN_GAMES,
    FAVORITE_GAME,
    UNFAVORITE_GAME,
    PREFER_GAME,
    GET_CUSTOMIZATION,
    POST_CUSTOMIZATION,
    GET_EMBED
} from "../store/actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    CLEAR_GROUPS,
    SET_GROUPS,
    APPEND_GROUP,
    SET_GROUP,
    REMOVE_GROUP,
    APPEND_SCREEN,
    SET_SCREEN,
    SET_FAVORITES,
    SET_PREFERRED,
    REMOVE_SCREEN,
    CLEAR_SCREEN_GAMES,
    SET_SCREEN_GAMES,
    SET_CUSTOMIZATION,
    SET_EMBED
} from "../store/mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        groups: [],
        games: [],
        embed: null,
        customization: null,
        loading: true,
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getGroupsLoading(state) {
        return state.loading;
    },
    getScreenGamesLoading(state) {
        return state.loading;
    },
    getGroups(state) {
        return state.groups;
    },
    getScreenGames(state) {
        return state.games;
    },
    getCurrentEmbed(state) {
        return state.embed;
    },
    getCurrentCustomization(state) {
        return state.customization;
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [CLEAR_GROUPS](state) {
        console.log("CLEAR_GROUPS");
        state.loading = true;
        state.groups = [];
    },
    [SET_GROUPS](state, data) {
        console.log("SET_GROUPS");
        state.loading = false;
        state.groups = data;
    },
    [SET_GROUP](state, data) {
        console.log("SET_GROUP");
        state.loading = false;

        let list = state.groups;
        for (var i=0; i < list.length; i++) {
            if (list[i].id == data.id) {
                Object.assign(list[i],data);
            }
        }
    },
    [APPEND_GROUP](state, data) {
        console.log("APPEND_GROUP");
        state.groups.push(data);
    },
    [REMOVE_GROUP](state, data) {
        console.log("REMOVE_GROUP");
        let group_index = searchArrayByKey(data.group_id,state.groups,'id','index');
        state.groups.splice(group_index,1);
    },
    [SET_SCREEN](state, data) {
        console.log("SET_SCREEN");
        state.loading = false;
        if (state.groups.length > 0) {
            let group = searchArrayByKey(data.group_id,state.groups);
            let list = group.screens;

            for (var i=0; i < list.length; i++) {
                if (list[i].id == data.id) {
                    Object.assign(list[i],data);
                }
            }
        }
        
    },
    [SET_FAVORITES](state, data) {
        console.log("SET_FAVORITES");
        state.loading = false;
        
        let group = searchArrayByKey(data.group_id,state.groups);
        let list = group.screens;

        for (var i=0; i < list.length; i++) {
            if (list[i].id == data.id) {
                Object.assign(list[i],data);
            }
        }
    },
    [SET_PREFERRED](state, data) {
        console.log("SET_PREFERRED");
        state.loading = false;
        
        let group = searchArrayByKey(data.group_id,state.groups);
        let list = group.screens;

        for (var i=0; i < list.length; i++) {
            if (list[i].id == data.id) {
                Object.assign(list[i],data);
            }
        }
    },
    [APPEND_SCREEN](state, data) {
        console.log("APPEND_SCREEN");
        if (state.groups.length > 0) {
            let group = searchArrayByKey(data.group_id,state.groups);
            group.screens.push(data);
        }
    },
    [REMOVE_SCREEN](state, data) {
        console.log("REMOVE_SCREEN");
        if (state.groups.length > 0) {
            let group = searchArrayByKey(data.group_id,state.groups);
            let screen_index = searchArrayByKey(data.screen_id,group.screens,'id','index');
            group.screens.splice(screen_index,1);
        }
    },
    [CLEAR_SCREEN_GAMES](state) {
        console.log("CLEAR_SCREEN_GAMES");
        state.loading = true;
        state.games = [];
    },
    [SET_SCREEN_GAMES](state, data) {
        console.log("SET_SCREEN_GAMES");
        state.loading = false;
        state.games = data;
    },
    [SET_CUSTOMIZATION](state, data) {
        console.log("SET_CUSTOMIZATION");
        state.customization = data;
    },
    [SET_EMBED](state, data) {
        console.log("SET_EMBED");
        state.embed = data;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_GROUPS](context, date = null) {
        console.log("GET_GROUPS");
        context.commit(CLEAR_GROUPS);
        var url = null;
        if (!date) {    
            url = "/groups";
        } else {
            url = "/groups/favorites/" + date
        }
        return new Promise((resolve, reject) => {
            ApiService.get(url)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_GROUPS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'groups',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_GROUP](context, group) {
        console.log("POST_GROUP");
        return new Promise((resolve, reject) => {
            ApiService.post("/groups", group)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_GROUP, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'groups',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [UPDATE_GROUP](context, group) {
        console.log("UPDATE_GROUP");
        return new Promise((resolve, reject) => {
            ApiService.patch("/groups/" + group.id, group)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_GROUP, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'groups',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [DELETE_GROUP](context, group) {
        console.log("DELETE_GROUP");
        return new Promise((resolve, reject) => {
            ApiService.delete("/groups/" + group.id)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_GROUP, {
                        group_id: group.id
                    });
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'groups',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_SCREEN](context, screen) {
        console.log("POST_SCREEN");
        return new Promise((resolve, reject) => {
            ApiService.post("/screens", screen)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(APPEND_SCREEN, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [UPDATE_SCREEN](context, screen) {
        console.log("UPDATE_SCREEN");
        return new Promise((resolve, reject) => {
            ApiService.patch("/screens/" + screen.id, screen)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_SCREEN, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [DELETE_SCREEN](context, screen) {
        console.log("DELETE_SCREEN");
        return new Promise((resolve, reject) => {
            ApiService.delete("/screens/" + screen.id)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(REMOVE_SCREEN, {
                        group_id: screen.group_id,
                        screen_id: screen.id
                    });
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_SCREEN_GAMES](context, date) {
        console.log("GET_SCREEN_GAMES");
        context.commit(CLEAR_SCREEN_GAMES);
        return new Promise((resolve, reject) => {
            ApiService.get("/screens/games/"+date)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SCREEN_GAMES, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'groups',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [FAVORITE_GAME](context, data) {
        console.log("FAVORITE_GAME");
        return new Promise((resolve, reject) => {
            ApiService.patch("/screens/" + data.screen_id + "/favorite", data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FAVORITES, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [UNFAVORITE_GAME](context, data) {
        console.log("UNFAVORITE_GAME");
        return new Promise((resolve, reject) => {
            ApiService.patch("/screens/" + data.screen_id + "/unfavorite", data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_FAVORITES, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [PREFER_GAME](context, data) {
        console.log("PREFER_GAME");
        return new Promise((resolve, reject) => {
            ApiService.patch("/screens/" + data.screen_id + "/prefer", data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_PREFERRED, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_CUSTOMIZATION](context) {
        console.log("GET_CUSTOMIZATION");
        return new Promise((resolve, reject) => {
            ApiService.get("/embed/customization")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CUSTOMIZATION, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_CUSTOMIZATION](context, data) {
        console.log("POST_CUSTOMIZATION");
        return new Promise((resolve, reject) => {
            ApiService.post("/embed/customization", data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_CUSTOMIZATION, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_EMBED](context, data) {
        console.log("GET_EMBED");
        return new Promise((resolve, reject) => {
            ApiService.get("embed/api/" + data)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_EMBED, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'screens',
                            message: response.data.message,
                            errors: response.data.errors
                        }
                    );
                    reject(response);
                });
        });
    }
};

export default {
    getters,
    actions,
    mutations,
    state
}
