<template>
  <v-container>
    <v-row justify="space-around" >
      <v-col cols="12" sm="8" md="6" lg="4">
        <div class="text-center mt-8 text-h6" v-if="!result">
          To see your new Regional Sports fee, please enter the ZIP code of the
          address where you recieve DIRECTV service.
        </div>
      </v-col>
    </v-row>
    <h5 v-if="error" class="red--text text-center mt-6">
      {{ error }}
    </h5>
    <v-row v-if="result" justify="space-around" class="mt-5">
      <v-col cols="12" sm="8" md="6" lg="4">
        <div class="text-center mt-4 text-h6">
          Your new monthly Regional Sports fee is:
        </div>
        <h1 class="text-center">
          ${{ result }}<span class="text-subtitle-1">/mo</span>
        </h1>
      </v-col>
    </v-row>
    <v-row justify="space-around" class="mt-10">
      <v-col
        :cols="$vuetify.breakpoint.width < 350 ? 12 : 10"
        sm="5"
        md="4"
        lg="3"
      >
        <v-text-field
          v-mask="mask"
          :rules="rules"
          :loading="loading"
          solo
          class="rounded-pill"
          color="black"
          label="Zip code"
          append-icon="fas fa-search"
          v-model="zip"
          @click:append="getFee"
          @change="getFee"
          @input="rate = null"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  
  <script>
import { mask } from "vue-the-mask";
import ApiService from "../api/api.service";

export default {
  name: "Rsfee",
  directives: {
    mask,
  },
  components: {},
  data() {
    return {
      loading: false,
      zip: null,
      error: null,
      result: null,
      mask: "#####-####",
      rules: [
        (v) =>
          /^0|[0-9]{5}(?:-[0-9]{4})?|null$/.test(v) ||
          "Please enter a valid zip code",
      ],
    };
  },
  computed: {
    testDate() {
        return this.$route.query.test_date || null;
    }
  },
  methods: {
    getFee() {
      this.error = null;
      this.result = null;
      this.loading = true;
      let post = { zip: this.zip };
      if (this.testDate) {
        post = { 
          zip: this.zip,
          test_date: this.testDate
        };
      }
      ApiService.post("/rsfee", post)
        .then(({ data }) => {
          if (data.message.length > 0) {
            this.error = data.message;
          }
          this.result = data.rate;
          this.loading = false;
        })
        .catch(({ response }) => {
          this.error = response.message;
          this.loading = false;
        });
      return;
    },
  },
  watch: {
    zip() {
      this.error = null;
      this.result = null;
    },
  },
};
</script>
  
<style scoped>
.v-text-field >>> .v-label {
  font-size: 1.5em;
}

.v-text-field >>> input {
  font-size: 1.5em;
  padding: 40px 30px;
}
::v-deep .v-input__slot {
  padding: 0 30px !important;
}
</style>