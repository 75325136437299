<template>
    <div :id="'game-showings-' + game.id">
        <template v-if="layout == 0 && !isMvp && game.isCommercial">
            <sportsbar :game="game" />
        </template>
        <template v-else>
            <h4
                v-if="showHeader"
                class="font-weight-bold"
                :class="layout == 1 ? 'my-3' : ''"
            >AVAILABLE ON</h4>
            <template v-if="layout == 0 && !isMvp">
                <v-row
                    no-gutters
                    justify="center"
                >
                    <v-col
                        cols="auto"
                        v-for="(showing, showing_index) in game.showings"
                        :key="showing_index"
                        @click="liteChannel(showing_index)"
                    >
                        <channel :channel="showing.channel" />
                    </v-col>
                </v-row>
            </template>
            <v-row v-if="expanded">
                <v-col
                    cols="12"
                    class="pt-0 text-left mx-2 pr-6"
                    :class="!showHeader ? 'my-3' : ''"
                >
                    <channel-details :channel="currentLitChannelDetails" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import Sportsbar from "./Sportsbar";
import Channel from "./Channel";
import ChannelDetails from "./ChannelDetails";

export default {
    name: "Showings",
    components: {
        Channel,
        ChannelDetails,
        Sportsbar,
    },
    props: {
        game: Object,
    },
    data() {
        return {
            currentLitChannel: 0,
            expanded: false,
        };
    },
    computed: {
        showings() {
            return this.game.showings;
        },
        currentLitChannelDetails() {
            return this.showings[this.currentLitChannel].channel;
        },
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },
        showHeader() {
            if (this.isMvp) return false;

            return (this.layout == 0) || (this.layout == 1 && this.expanded);
        },
        currentZip() {
            return this.$store.getters.getCurrentSelectedOptions("zip");
        },
        sportsBarUrl() {
            let url = `https://sportsbarfinder.com/`;
            if (this.currentZip && this.currentZip.length > 4) {
                url += `?zip=${this.currentZip}`;
            }
            return url;
        },
    },
    methods: {
        liteChannel(index) {
            console.log("liteChannel", index);
            //   this.$gtag.event("api", {
            //     event_category: "event",
            //     event_label: "expand_event",
            //   });

            if (this.currentLitChannel == index && this.expanded) {
                this.currentLitChannel = null;
                this.expanded = false;
                return false;
            } else {
                this.currentLitChannel = index;
                this.expanded = true;
                return true;
            }
        },
    },
};
</script>
