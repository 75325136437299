<template>
    <a :href="sportsBarUrl" target="_blank">
        Watch at a DIRECTV Bar
    </a>
</template>

<script>
export default {
    name: "Sportsbar",
    computed: {
        currentZip() {
            return this.$store.getters.getCurrentSelectedOptions("zip");
        },
        sportsBarUrl() {
            let url = `https://sportsbarfinder.com/`;
            if (this.currentZip && this.currentZip.length > 4) {
                url += `?zip=${this.currentZip}`;
            }
            return url;
        },
    },
};
</script>
