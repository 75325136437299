import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";

import {
    CHECK_AUTH
} from "../store/actions.type";

/**
 * Route Components
 */
import Home from "../views/Home";
import Season from "../views/Season";


//League
import Teams from "../views/Leagues/Teams";
import Standings from "../views/Leagues/Standings";
import Players from "../views/Leagues/Players";
import Coaches from "../views/Leagues/Coaches";
import DepthCharts from "../views/Leagues/DepthCharts";
import FantasyProjections from "../views/Leagues/FantasyProjections";
import Leaders from "../views/Leagues/Leaders";

//Team


//Player


//Other
import Mvp from "../views/Mvp";
import Planner from "../views/Planner";
import EmbedEdit from "../views/EmbedEdit";
import Rsfee from "../views/Rsfee";
import Rsinfo from "../views/Rsinfo";
import NotFound from "../views/NotFound";
import Settings from "../views/Settings";
import Embed from "../views/Embed";
import PasswordReset from "../views/PasswordReset";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.MIX_BASE_URL,
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            reroute: true,
            showbar: true,
            showfooter: true,
            printable: true,
            title: "Home"
        }
    },
    {
        path: '/leagues/:league/teams',
        name: 'teams',
        component: Teams,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Teams",
            layout: "Details",
            canonicalAddon: "teams",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/standings',
        name: 'standings',
        component: Standings,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Standings",
            layout: "Details",
            canonicalAddon: "standings",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/players',
        name: 'league-players',
        component: Players,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Players",
            layout: "Details",
            canonicalAddon: "players",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/teams/:team/players',
        name: 'team-players',
        component: Players,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Players",
            layout: "Details",
            canonicalAddon: "players",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/coaches',
        name: 'coaches',
        component: Coaches,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Coaches",
            layout: "Details",
            canonicalAddon: "coaches",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/depth-charts',
        name: 'depth-charts',
        component: DepthCharts,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Depth Charts",
            layout: "Details",
            canonicalAddon: "depth-charts",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/fantasy-projections',
        name: 'fantasy-projections',
        component: FantasyProjections,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Fantasy Projections",
            layout: "Details",
            canonicalAddon: "fantasy-projections",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/leaders',
        name: 'leaders',
        component: Leaders,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: false,
            title: "Leaders",
            layout: "Details",
            canonicalAddon: "leaders",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/schedule/:year?/:month?',
        name: 'league-schedule',
        component: Season,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: true,
            title: "Schedule",
            layout: "Details",
            canonicalAddon: "schedule",
            //layout: "Base",
        }
    },
    {
        path: '/leagues/:league/teams/:team/schedule/:year?/:month?',
        name: 'team-schedule',
        component: Season,
        meta: {
            reroute: false,
            showbar: true,
            showfooter: true,
            printable: true,
            title: "Schedule",
            layout: "Details",
            canonicalAddon: "schedule",
            //layout: "Base",
        }
    },

    {
        path: '/planner',
        name: 'planner',
        component: Planner,
        meta: {
            reroute: false,
            showbar: true,
            printable: true,
            title: "Planner",
            canonicalAddon: "planner",
        }
    },
    {
        path: '/embededit',
        name: 'embededit',
        component: EmbedEdit,
        meta: {
            reroute: false,
            showbar: true,
            printable: true,
            title: "Embed Editor",
            canonicalAddon: "embededit",
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            reroute: false,
            showbar: true,
            title: "Planner",
            canonicalAddon: "settings",
        }
    },
    {
        path: '/rsfee',
        name: 'rsfee',
        component: Rsfee,
        meta: {
            reroute: false,
            showbar: false,
            printable: false,
            title: "Regional Sports Locator",
            canonicalAddon: "rsfee",
        }
    },
    {
        path: '/rsinfo',
        name: 'rsinfo',
        component: Rsinfo,
        meta: {
            reroute: false,
            showbar: false,
            printable: false,
            title: "Local Regional Sports Network Information",
            canonicalAddon: "rsinfo",
        }
    },
    {
        path: '/rsinfocom',
        name: 'rsinfocom',
        component: Rsinfo,
        meta: {
            reroute: false,
            showbar: false,
            printable: false,
            title: "Commercial Local Regional Sports Network Information",
            canonicalAddon: "rsinfocom",
        }
    },
    {
        path: '/embed/:groupuid',
        name: 'embed',
        component: Embed,
        meta: {
            reroute: false,
            showbar: false,
            showfooter: false,
            printable: false,
            title: "Embed",
            canonicalAddon: "embed",
        }
    },
    {
        path: '/mvp',
        name: 'mvp',
        component: Mvp,
        meta: {
            reroute: false,
            showbar: false,
            showfooter: false,
            printable: false,
            title: "MVP",
            canonicalAddon: "mvp",
        }
    },
    {
        path: '/:sport/:league',
        name: 'home_params',
        component: Home,
        meta: {
            reroute: true,
            showbar: true,
            showfooter: true,
            printable: true,
            title: "Events"
        }
    },
    {
        path: '/password/reset/:token',
        name: 'passwordreset',
        component: PasswordReset,
        meta: {
            showbar: true,
            showfooter: true,
            printable: true,
            title: "Password Reset"
        }
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            reroute: true,
            showbar: true,
            title: "Error"
        }
    }
    ]
});

router.beforeEach((to, from, next) =>
    Promise.all([store.dispatch(CHECK_AUTH)]).then(next)
);

router.afterEach((to, from) => {
    if (!to.reroute) {

        const canonical = document.querySelector('link[rel="canonical"]');
        const metaog = document.querySelector('meta[name="og:url"]');
        const metatwitter = document.querySelector('meta[name="twitter:url"]');

        let currentUrl = window.location.href;
        //remove query string
        currentUrl = currentUrl.split("?")[0];

        canonical.href = currentUrl;
        metaog.content = currentUrl;
        metatwitter.content = currentUrl;

    }
});

export default router;
