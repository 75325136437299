<template>
  <v-card v-if="object && type">
    <v-card-title
      >Configure suggestions for {{ type == 'group' ? 'Location' : type.capitalize() }} -
      {{ object.name }}</v-card-title
    >
    <v-card-subtitle class="py-0"
      >You can customize your suggestions.
      <span v-if="type == 'group'" class="red--text"
        >Customizations here will override your default customizations.</span
      >
      <span v-if="type == 'screen'" class="red--text"
        >Customizations here will override the location's customizations.</span
      >
    </v-card-subtitle>

    <v-card-text style="height: 600px">
      <v-row
        no-gutters
        v-for="(setting, index) in object.settings"
        v-bind:key="setting.key"
      >
        <v-col cols="auto">
          <v-icon color="red" class="mt-5 mx-4" @click="removeOption(index)"> mdi-cancel </v-icon>
        </v-col>
        <v-col cols="11" class="py-1">
          <Field :option="setting" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn large color="success" @click="addCustomizationDialog = true">
            <v-icon left>mdi-plus</v-icon> Add Customization
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn color="error" large @click="deleteObject">
        DELETE {{ type == 'group' ? 'Location' : type.capitalize() }}
        <v-icon right> mdi-delete </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn large @click="$emit('done')">Cancel</v-btn>
      <v-btn color="primary" large @click="finish">Save</v-btn>
    </v-card-actions>

    <v-dialog v-model="addCustomizationDialog" max-width="460">
      <add-customization-modal
        :settings="object.settings"
        @add-option="addOption"
        @done="addCustomizationDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="deleteScreenDialog" max-width="560">
      <delete-screen-modal :screen="object" @done="done" />
    </v-dialog>

    <v-dialog v-model="deleteGroupDialog" max-width="560">
      <delete-group-modal :group="object" @done="done" />
    </v-dialog>


  </v-card>
</template>

<script>
import AddCustomizationModal from "./AddCustomizationModal";
import Field from "../../components/Scheduler/Options/Field";

import DeleteGroupModal from "./DeleteGroupModal";
import DeleteScreenModal from "./DeleteScreenModal";

import { UPDATE_GROUP, UPDATE_SCREEN } from "../../store/actions.type";

export default {
  name: "ConfigureModal",
  components: {
    AddCustomizationModal,
    DeleteGroupModal,
    DeleteScreenModal,
    Field,
  },
  data: function () {
    return {
      addCustomizationDialog: false,
      deleteGroupDialog: false,
      deleteScreenDialog: false,
    };
  },
  props: {
    object: Object,
    type: String,
  },
  methods: {
    addOption(option) {
      this.addCustomizationDialog = false;
      if (!this.object.settings) {
        this.object.settings = [];
      }
      option.selectedOptions = [];
      this.object.settings.push(option);
    },
    removeOption(index) {
      this.object.settings.splice(index, 1);
    },
    finish() {
      if (this.type == "group") {
        this.$store
          .dispatch(UPDATE_GROUP, this.object)
          .then(() => this.$emit("done"));
      }
      if (this.type == "screen") {
        this.$store
          .dispatch(UPDATE_SCREEN, this.object)
          .then(() => this.$emit("done"));
      }
    },
    deleteObject() {
      if (this.type == "group") {
        this.deleteGroup(this.object);
      } else {
        this.deleteScreen(this.object);
      }
    },
    deleteGroup() {
      this.deleteGroupDialog = true;
    },
    deleteScreen() {
      this.deleteScreenDialog = true;
    },
    done() {
      this.deleteGroupDialog = false;
      this.deleteScreenDialog = false;
      this.$emit("done");
    }
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
