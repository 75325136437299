<template>
    <v-row
        class="lightGray pb-16 mb-16 pr-2 mt-16"
        justify="space-around"
    >
        <v-col
            cols="auto"
            v-for="sport in sports"
            :key="sport.text"
        >
            <a
                class="sport-button v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default text-center"
                @click="updated(sport.value)"
            >
                <span class="v-btn__content">
                    <v-icon
                        x-large
                        :color="sport.value == selectedOptions ? 'primary' : null
                            "
                    >{{ sport.icon }}</v-icon>

                    <div
                        :class="sport.value == selectedOptions ? 'active-website-button' : null
                            "
                        class="
                            mt-2
                            text-center text-uppercase
                            font-weight-bold
                            sport-button-text
                        "
                    >
                        {{ sport.text }}
                    </div>
                </span>
            </a>
        </v-col>
        <!--
    <v-col cols="12" class="text-center py-1" v-for="item in items" :key="item.text">
      <v-btn
        exact
        text
        @click="updated(item.value)"
        :class="item.value == selectedOptions ? 'active-website-button' : null"
        class="text-uppercase font-weight-bold sport-button"
        >{{ item.text }}</v-btn
      >
    </v-col>
    -->
    </v-row>
</template>

<script>
import { POST_SELECTED_OPTION } from "../../store/actions.type";
import { CLOSE_MOBILE_MENUS, OPEN_LEAGUES_MODAL } from "../../store/mutations.type";

export default {
    name: "MobileSports",
    computed: {
        option() {
            return this.$store.getters.getSingleOption("sports");
        },
        selectedOptions() {
            return this.$store.getters.getCurrentSelectedOptions(this.option.key);
        },
        sports: function () {
            //Object.values forces it to reindex, so the break is maintained.
            return this.option.options;
        },

    },
    methods: {
        updated(options) {
            let key = this.option.key;

            if (options == 99) {
                this.$store.commit(OPEN_LEAGUES_MODAL);
            }

            //   this.$gtag.event("api", {
            //     event_category: "options",
            //     event_label: "post_selected_option",
            //     value: options,
            //   });
            this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
                this.loading = false;
                this.$store.commit(CLOSE_MOBILE_MENUS);
            });
        },
    },
};
</script>

<style scoped>
::v-deep i {
    font-style: normal;
}

::v-deep .v-btn__content {
    display: block;
}

.sport-button {
    width: 150px !important;
    height: 150px !important;
}

.sport-button-text {
    font-size: 1.2rem;
    letter-spacing: 0.02em;
}

.active-website-button {
    color: #196DD4;
}

.sport-button:hover::after {
    content: "";
    background: #196DD4;
    position: absolute;
    bottom: 0;
    margin-left: auto;
    height: 2px;
    width: 40px;
}

.active-website-button:after {
    background: #196DD4;
}

.sport-tooltip-button {
    font-size: 1.1em;
    width: 120px;
    letter-spacing: 0.02em;
}
</style>
