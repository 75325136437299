<template>
  <v-row no-gutters v-if="!loading && games && games.length">
    <v-col>
      <v-row
        v-if="!isEmbed && !isMvp && layout == 0"
        :no-gutters="isMobile"
        justify="space-around"
        class="d-print-none mx-md-3"
      >
        <template v-for="(game, game_index) in games">
          <!--  Show an ad every 5 games -->
          <v-col
            class="mt-6 pt-8"
            cols="auto"
          >
            <game :game="game" :key="game.id" :show-header="showListHeader(game_index, false)" />
          </v-col>
        </template>

                <v-col
                    cols="12"
                    id="infinload"
                >
                    <infinite-loading
                        v-if="false"
                        class="mt-8"
                        @distance="1"
                        @infinite="getMoreGames"
                        :identifier="gamesInfiniteLoader"
                    >
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                    <v-row
                        class="d-print-none mb-16"
                        no-gutters
                    >
                        <v-col class="text-center gray--text mb-16 mt-5">
                            No more events listed.
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Dense Table -->
            <compact
                :games="games"
                v-if="isEmbed || isMvp || layout > 0"
            />

      <!-- PRINT TABLE -->
      <v-simple-table class="d-none d-print-block">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="showListTitles" class="text-left">{{ tableFirstColumnName }}</th>
              <th class="text-left">Event</th>
              <th class="text-left">Time</th>
              <th class="text-left">Channels</th>
            </tr>
          </thead>
          <tbody
            v-for="(game, game_index) in printableGames"
            :key="game_index"
            :class="game_index % 2 ? 'tinted' : ''"
          >
            <tr v-if="showListHeader(game_index, true)">
              <td :colspan="showListTitles ? 4 : 3" >
                <v-row no-gutters align="center">
                  <v-col><v-divider></v-divider></v-col>
                  <v-col cols="auto"><strong class="mx-4">{{ titleDisplay(game, true) }}</strong></v-col>
                  <v-col><v-divider></v-divider></v-col>
                </v-row>
              </td>
            </tr>
            <tr class="game-print-row">
              <td v-if="showListTitles">{{ titleDisplay(game) }}</td>
              <td>
                <div v-if="game.description">
                  {{ game.description }}
                </div>
                <div v-else>
                  {{ game.away_team.display_name_short }} at
                  {{ game.home_team.display_name_short }}
                </div>
              </td>
              <td style="white-space: nowrap">
                <time-display :game="game" />
              </td>
              <td>
                <v-row no-gutters class="mt-0">
                  <channel-print
                    v-for="(showing, showing_index) in game.showings"
                    :key="showing_index"
                    :channel="showing.channel"
                    :index="showing_index"
                  />
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>

    <loading v-else-if="loading"></loading>
    <no-games v-else></no-games>
</template>

<script>
import CustomerLocation from "./CustomerLocation";
import Game from "./Games/Game";
import Compact from "./Games/Game/Compact";
import Showings from "./Games/Game/Showings";
import Loading from "../shared/Loading";
import NoGames from "./Games/NoGames";

import TimeDisplay from "./Games/Game/Shared/TimeDisplay";
import Channel from "./Games/Game/Channel";
import ChannelPrint from "./Games/Game/ChannelPrint";
import SportBadgeDense from "./Games/Dense/SportBadgeDense";
import Teams from "./Games/Game/Teams.vue";


export default {
  name: "Games",
  components: {
    Game,
    Compact,
    Showings,
    Loading,
    NoGames,
    TimeDisplay,
    Channel,
    ChannelPrint,
    SportBadgeDense,
    Teams,
  },
  props: {
    games: {
      default: [],
      type: Array,
    },
  },
  computed: {
    showbar() {
      return this.$route.meta.showbar;
    },
    loading() {
      return this.$store.getters.getGamesLoading;
    },
    layout() {
      return this.$store.getters.getCurrentSelectedOptions("layout");
    },
    groupBy() {
      return this.$store.getters.getCurrentSelectedOptions("groups");
    },
    timezone() {
      return this.$store.getters.getCurrentSelectedOptions("timezoneString");
    },
    gamesInfiniteLoader() {
      return this.$store.getters.getGamesInfiniteLoader;
    },
    tableFirstColumnName() {
      if (this.$route.matched.some((r) => r.name == "league-schedule" || r.name == "team-schedule")) return "Date";

      return "League";
    },
    showListTitles() {
      return this.groupBy != 2 ? true : false;
    },
    printableGames() {
        return this.games.filter((game) => game.showings && game.showings.length);
    },
  },
  methods: {
    getMoreGames($state) {
      console.log("get more games");
      let currentQty = this.tempGames.length;
      let total = this.games.length;
      let qtyRemaining = total - currentQty;

            if (total > 0 && qtyRemaining > 0) {
                if (qtyRemaining > 12) qtyRemaining = 12;
                for (let i = currentQty; i < currentQty + qtyRemaining; i++) {
                    this.tempGames.push(JSON.parse(JSON.stringify(this.games[i])));
                }
            }

            currentQty = this.tempGames.length;
            qtyRemaining = total - currentQty;

      console.log(qtyRemaining);
      if (qtyRemaining <= 0) {
        console.log("State complete");
        $state.complete();
      } else {
        console.log("State loaded");
        $state.loaded();
      }
    },
    showListHeader(game_index, printView) {
      //Season Schedule
      if (this.$route.matched.some((r) => r.name == "league-schedule" || r.name == "team-schedule")) {
        if (game_index == 0) return true;

                return (
                    this.titleDisplay(this.games[game_index]) !=
                    this.titleDisplay(this.games[game_index - 1])
                );
            }

            //If not grouping, or route has a schedule bar already, then false
            if (!this.groupBy || this.$route.meta.showbar == false) {
                if (printView) {
                    return false;
                }
                return this.layout ? false : true;
            }


      //Otherwise compare current index to previous index on desired field
      switch (this.groupBy) {
        case 1:
          //Sport
          //If first game, always show when grouping
          if (game_index == 0) return true;
          if (
            this.games[game_index].sport_id !=
            this.games[game_index - 1].sport_id
          )
            return true;
          return false;
        case 2:
          //League
          //If first game, always show when grouping
          if (game_index == 0) return true;
          if (
            this.games[game_index].league_id !=
            this.games[game_index - 1].league_id
          )
            return true;
          return false;
        case 3:
          if (printView) {
            return false;
          }
          return this.layout ? false : true;
      }
      return true;
    },
    titleDisplay(game, group = false) {
      if (this.$route.matched.some((r) => r.name == "league-schedule" || r.name == "team-schedule"))
        return this.gameDate(game);

            switch (this.groupBy) {
                case 1:
                    return group ? game.sport.title : game.league.title_short;
                default:
                    return game.league.title_short;
            }
        },
        gameDate(game) {
            if (!game) return null;

            let gameDateTime =
                game.showings && game.showings.length
                    ? game.showings[0].display_at
                    : game.starts_at;
            let dateTime = moment.utc(gameDateTime);
            return dateTime.tz(this.timezone).format("ddd, MMM Do");
        },
        gameTime(game) {
            let gameDateTime =
                game.showings && game.showings.length
                    ? game.showings[0].display_at
                    : game.starts_at;
            return moment.utc(gameDateTime).tz(this.timezone).format("h:mm A");
        },
        liteChannel(game_index, showing_index) {
            this.$refs.showings[game_index].liteChannel(showing_index);
        },
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue == true) {
                this.tempGames = [];
            }
        },
    },
};
</script>

<style scoped>
.blue-line {
    background: linear-gradient(180deg,
            rgba(0, 0, 0, 0) calc(50% - 1px),
            rgba(0, 87, 184, 1) calc(50%),
            rgba(0, 0, 0, 0) calc(50% + 1px));
}

.group-header-text {
    font-size: 1.48em !important;
    letter-spacing: 0em !important;
}

.game-divider {
    border-bottom: 1px solid black;
}

.tinted {
    background: #f2f2f2;
}

@media print {
    td {
        height: 40px !important;
    }
}
</style>
