<template>
    <v-card class="game-details-container">
        <v-row
            class="px-1 group-header"
            :class="[!(showHeader || hasFavorite) ? 'mt-6' : '']"
            align="center"
            no-gutters
        >

            <v-col
                cols="auto"
                class="mr-1"
            >
                <v-card
                    :href="!isEmbed && game.league.show_season_schedule ? `${appUrl}/leagues/${game.league.slug.toUpperCase()}/schedule` : ''"
                    flat
                    color="transparent"
                >
                    <v-img
                        v-if="game.league.has_logo"
                        contain
                        content-class="contain-paint"
                        transition="none"
                        :src="`${appUrl}/img/logos/leagues/${game.league.slug}.svg`"
                        width="58"
                        height="58"
                        :alt="game.league.title + ' Logo'"
                    />
                    <v-card
                        v-else
                        flat
                        width="58"
                        height="58"
                        color="transparent"
                    ></v-card>
                </v-card>
            </v-col>
            <v-col class="white--text">
                <v-img
                    v-if="game.logo"
                    contain
                    class="mx-auto"
                    content-class="contain-paint"
                    transition="none"
                    :src="`${appUrl}/img/logos/games/${game.logo}.svg`"
                    height="58"
                    max-width="175"
                    :alt="'Game Logo'"
                />
                <template v-else>
                    <h2 class="text-uppercase group-header-text text-center">{{ titleDisplay }}</h2>
                </template>
                <div
                    class="text-center"
                    v-if="isDebugMode"
                >
                    {{ game.id }}
                </div>

            </v-col>
            <v-col cols="auto">
                <favorite-hype-badge
                    class="ma-2"
                    :game="game"
                    :showHeader="showHeader"
                />
            </v-col>

        </v-row>
        <v-row no-gutters>
            <v-col
                class="game-details"
                v-if="game.description"
            >
                <v-row
                    no-gutters
                    class="fill-height"
                >
                    <v-col
                        cols="12"
                        class="text-center py-2"
                    >
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                class="text-center format-icons"
                            >
                                <v-icon
                                    color="primary"
                                    size="32"
                                    v-if="hasUhd"
                                >
                                    mdi-video-4k-box
                                </v-icon>
                                <v-icon
                                    color="primary"
                                    size="32"
                                    v-if="hasHd"
                                >
                                    mdi-high-definition-box
                                </v-icon>
                            </v-col>
                        </v-row>
                        <v-row
                            no-gutters
                            class="mt-5 mx-2"
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <h3 v-html="game.description" />
                                <h2 v-if="game.game_status_id == 1">{{ gameTime }}</h2>
                                <h3
                                    :class="`${game.game_status.color}--text`"
                                    v-if="[2, 4].includes(game.game_status_id)"
                                >
                                    {{
                game.game_status_reason
                    ? game.game_status_reason
                    : game.game_status.name
            }}
                                </h3>

                                <score
                                    :game="game"
                                    v-if="[2, 4].includes(game.game_status_id) &&
                game.away_score !== null &&
                game.home_score !== null
                "
                                />
                                <h2
                                    :class="`${game.game_status.color}--text`"
                                    v-if="game.game_status_id > 4"
                                >
                                    {{
                game.game_status_reason
                    ? game.game_status_reason
                    : game.game_status.name
            }}
                                </h2>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-else
                class="game-details-matchup"
                :style="gameDetailsStyle(game)"
            >
                <v-row
                    no-gutters
                    class="fill-height"
                >
                    <v-col
                        cols="4"
                        class="pl-1"
                    >
                        <!-- away team-->
                        <v-row
                            no-gutters
                            align="center"
                            class="fill-height py-1"
                        >
                            <v-col
                                v-if="game.away_team.logo_exists"
                                cols="12"
                            >
                                <template v-if="!isEmbed && game.league.show_season_schedule">
                                    <a
                                        :href="`${appUrl}/leagues/${game.away_team.league_slug.toUpperCase()}/teams/${game.away_team.text_id.toUpperCase()}/schedule`">
                                        <v-img
                                            :src="`${appUrl}/img/logos/teams/${game.away_team.master_team_id ? game.away_team.master_team_id : game.away_team.id}.svg`"
                                            :width="logoSize"
                                            :height="logoSize"
                                            class="mx-auto"
                                            contain
                                            content-class="contain-paint team-logo"
                                            transition="none"
                                            :alt="game.away_team.display_name_short + ' Logo'"
                                        />
                                    </a>
                                </template>
                                <template v-else>
                                    <v-img
                                        :src="`${appUrl}/img/logos/teams/${game.away_team.master_team_id ? game.away_team.master_team_id : game.away_team.id}.svg`"
                                        :width="logoSize"
                                        :height="logoSize"
                                        class="mx-auto"
                                        contain
                                        content-class="contain-paint team-logo"
                                        transition="none"
                                        :alt="game.away_team.display_name_short + ' Logo'"
                                    />
                                </template>
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center"
                                :class="game.away_team.logo_exists ? '' : 'mb-4'"
                            >
                                <template v-if="!isEmbed && game.league.show_season_schedule">
                                    <a :href="appUrl + '/leagues/' +
                game.away_team.league_slug.toUpperCase() +
                '/teams/' +
                game.away_team.text_id.toUpperCase() +
                '/schedule'
                ">
                                        <div class="team-name">{{ game.away_team.display_name_short.toUpperCase() }}
                                        </div>
                                    </a>
                                </template>
                                <template v-else>
                                    <div class="team-name">{{ game.away_team.display_name_short.toUpperCase() }}</div>
                                </template>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="4"
                        class="text-center d-flex flex-column justify-space-between py-2"
                    >
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                class="text-center format-icons"
                            >
                                <v-icon
                                    color="primary"
                                    size="32"
                                    v-if="hasUhd"
                                >
                                    mdi-video-4k-box
                                </v-icon>
                                <v-icon
                                    color="primary"
                                    size="32"
                                    v-if="hasHd"
                                >
                                    mdi-high-definition-box
                                </v-icon>
                            </v-col>
                        </v-row>
                        <v-row
                            no-gutters
                            class="fill-height"
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <h2 v-if="game.game_status_id == 1">{{ gameTime }}</h2>
                                <h3
                                    :class="`${game.game_status.color}--text`"
                                    v-if="[2, 4].includes(game.game_status_id)"
                                >
                                    {{
                game.game_status_reason
                    ? game.game_status_reason
                    : game.game_status.name
            }}
                                </h3>

                                <score
                                    :game="game"
                                    v-if="[2, 4].includes(game.game_status_id) &&
                game.away_score !== null &&
                game.home_score !== null
                "
                                />
                                <h2
                                    :class="`${game.game_status.color}--text`"
                                    v-if="game.game_status_id > 4"
                                >
                                    {{
                game.game_status_reason
                    ? game.game_status_reason
                    : game.game_status.name
            }}
                                </h2>

                            </v-col>
                        </v-row>
                        <win-probability :game="game" />
                    </v-col>
                    <v-col
                        cols="4"
                        class="pr-1"
                    >
                        <!-- home team-->
                        <v-row
                            no-gutters
                            align="center"
                            class="fill-height py-1"
                        >
                            <v-col
                                cols="12"
                                class="text-center"
                                :class="game.home_team.logo_exists ? '' : 'mb-4'"
                            >
                                <template v-if="!isEmbed && game.league.show_season_schedule">
                                    <a :href="appUrl + '/leagues/' +
                game.home_team.league_slug.toUpperCase() +
                '/teams/' +
                game.home_team.text_id.toUpperCase() +
                '/schedule'
                ">

                                        <div class="team-name">{{ game.home_team.display_name_short.toUpperCase() }}
                                        </div>
                                    </a>
                                </template>
                                <template v-else>
                                    <div class="team-name">{{ game.home_team.display_name_short.toUpperCase() }}</div>
                                </template>
                            </v-col>
                            <v-col
                                v-if="game.home_team.logo_exists"
                                cols="12"
                            >
                                <template v-if="!isEmbed && game.league.show_season_schedule">
                                    <a :href="appUrl + '/leagues/' +
                                        game.home_team.league_slug.toUpperCase() +
                                        '/teams/' +
                                        game.home_team.text_id.toUpperCase() +
                                        '/schedule'
                                    ">
                                        <v-img
                                            :src="`${appUrl}/img/logos/teams/${game.home_team.master_team_id ? game.home_team.master_team_id : game.home_team.id}.svg`"
                                            :width="logoSize"
                                            :height="logoSize"
                                            class="mx-auto"
                                            contain
                                            content-class="contain-paint team-logo"
                                            transition="none"
                                            :alt="game.home_team.display_name_short + ' Logo'"
                                        />
                                    </a>
                                </template>
                                <template v-else>
                                    <v-img
                                        :src="`${appUrl}/img/logos/teams/${game.home_team.master_team_id ? game.home_team.master_team_id : game.home_team.id}.svg`"
                                        :width="logoSize"
                                        :height="logoSize"
                                        class="mx-auto"
                                        contain
                                        content-class="contain-paint team-logo"
                                        transition="none"
                                        :alt="game.home_team.display_name_short + ' Logo'"
                                    />
                                </template>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <v-row no-gutters>
            <v-col class="text-center">
                <v-btn
                    color="primary"
                    class="mt-4 white--text"
                >
                    <v-icon
                        left
                        dark
                    >
                        mdi-map-marker-radius-outline
                    </v-icon>
                    Find this game at a bar or restaurant
                </v-btn>
            </v-col>
        </v-row> -->
        <v-row no-gutters>
            <v-col
                cols="12"
                class=""
            >
                <v-row no-gutters class="my-1">
                    <v-col
                        cols="12"
                        class="text-center"
                    >
                        <showings :game="game" />
                    </v-col>
                </v-row>
                <game-bulletins
                    :game="game"
                />
                <v-row
                    no-gutters
                    v-if="game.thuuz_game"
                    class="mb-2 mx-1"
                >
                    <v-col class="text-center">
                        <div v-html="game.thuuz_game.teaser_default" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <betting-odds
            class="mx-4 mx-sm-11 mt-0"
            v-if="hasBettingOdds"
            :game="game"
        />

    </v-card>
</template>

<script>
import SportBadge from "./Game/SportBadge";
import FavoriteHypeBadge from "./Game/FavoriteHypeBadge";
import GameBulletins from "./Game/GameBulletins";
import GameTitle from "./Game/GameTitle";
import GameDetails from "./Game/GameDetails";
import Showings from "./Game/Showings";
import BettingOdds from "./Game/BettingOdds";
import WinProbability from "./Game/Hype/WinProbability";
import Score from "./Game/Teams/Score";

export default {
    name: "Game",
    components: {
        GameBulletins,
        GameTitle,
        GameDetails,
        SportBadge,
        FavoriteHypeBadge,
        Showings,
        BettingOdds,
        WinProbability,
        Score
    },
    props: {
        game: Object,
        showHeader: Boolean,
    },
    data() {
        return {
            logoSize: 90,
        };
    },
    computed: {
        titleDisplay() {
            if (this.$route.matched.some((r) => r.name == "league-schedule"))
                return this.gameDate;
            if (this.$route.matched.some((r) => r.name == "team-schedule"))
                return this.gameDate;
            if (this.$route.matched.some((r) => r.name == "season"))
                return this.gameDate;

            return this.game.league.title_short;
        },
        hasFavorite() {
            return true;
        },
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezoneString");
        },
        gameDate: function () {
            let gameDateTime =
                this.game.showings && this.game.showings.length
                    ? this.game.showings[0].display_at
                    : this.game.starts_at;
            let dateTime = moment(gameDateTime);
            return dateTime.utc(gameDateTime).tz(this.timezone).format("ddd, MMM Do");
        },
        gameTime: function () {
            let gameDateTime =
                this.game.showings && this.game.showings.length
                    ? this.game.showings[0].display_at
                    : this.game.starts_at;
            return moment.utc(gameDateTime).tz(this.timezone).format("h:mm A");
        },
        hasBettingOdds() {
            return this.game.betting_odds;
        },
        hasHd() {
            //If any of the showings have hd, then return true, otherwise return false
            return this.game.showings.some((showing) => {
                return showing.hd;
            });
        },
        hasUhd() {
            //If any of the showings have uhd, then return true, otherwise return false
            return this.game.showings.some((showing) => {
                return showing.uhd;
            });
        },
    },
    methods: {
        gameDetailsStyle(game) {
            let awayTeamColor = game.away_team.color1;
            let homeTeamColor = game.home_team.color1;

            let degreeDifference = 22;

            //final style string
            let style = "";

            //style += `background: white;`;
            style += `background: linear-gradient(135deg, ${awayTeamColor} 0%, ${awayTeamColor} ${degreeDifference}%, rgba(255,255,255,1) ${degreeDifference}%, rgba(255,255,255,1) ${100 - degreeDifference}%, ${homeTeamColor} ${100 - degreeDifference}%, ${homeTeamColor} 100%);`;


            return style;
        },
    },
};
</script>

<style scoped>
.group-header {
    width: 408px;
    background: #196DD4;

}

.group-header-text {
    font-size: 1.4em !important;
    font-weight: 900;
    letter-spacing: 0em !important;
}

.game-details-container {
    width: 408px;
}

.game-details,
.game-details-matchup {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.8);
    height: 170px;
}

.game-details-matchup::before {
    content: "@";
    position: absolute;
    font-size: 98px;
    /* Adjust the size of the @ symbol */
    color: rgba(0, 0, 0, 0.03);
    /* Adjust color and opacity */
    top: 150px;
    /* Center the symbol vertically */
    left: 50%;
    /* Center the symbol horizontally */
    transform: translate(-50%, -50%);
}

.team-logo {
    background: white;
}

.team-name {
    font-weight: 900;
}

@media screen and (max-width: 412px) {
    .group-header {
        width: calc(100vw - 4px);
    }

    .game-details-container {
        width: calc(100vw - 4px);
    }
}

.format-icons {
    height: 32px;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: black;
}

a:active {
    text-decoration: none;
    color: black;
}
</style>
