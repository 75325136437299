<template>
    <div>
        <v-row
            class="dtv-app-bar white--text mt-print-0 mt-6  mt-md-0"
            no-gutters
        >
            <v-col cols="12">
                <month-bar />
            </v-col>
        </v-row>
        <v-row no-gutters justify="center">
            <v-col
                cols="10"
                class="d-print-none mt-6
                mb-2"
            >
                <dtv-ads />
            </v-col>
        </v-row>
        <v-row
            align="center"
            class="d-print-none mx-3 justify-space-around justify-lg-center"
        >

            <v-col
                cols="5"
                md="5"
                lg="4"
                xl="3"
                class="text-center"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <layout-toggle :option="layoutOption" />

            </v-col>

            <v-col
                cols="12"
                md="2"
                class="text-center"
                style="font-size: 2em"
            >
                <v-row
                    no-gutters
                    justify="space-around"
                >
                    <v-col cols="auto">
                        <v-menu
                            ref="dateMenu"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="selectedDays"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="selectedDays.length"
                                    large
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ ordinalSuffixOf(selectedDays[0].slice(-2)) + (selectedDays.length > 1 ? '-' +
                                        ordinalSuffixOf(selectedDays[1].slice(-2)) : '') }}
                                    <v-icon right>mdi-wrench</v-icon>
                                </v-btn>
                            </template>
                            <v-date-picker
                                v-model="selectedDays"
                                range
                                no-title
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dateMenu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dateMenu.save(selectedDays)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                        cols="auto"
                        v-if="isMobile"
                    >
                        <v-btn
                            large
                            @click="print"
                            color="primary"
                            v-if="$route.meta.printable"
                        >
                            <v-icon> mdi-printer </v-icon>
                            Print
                        </v-btn>
                    </v-col>
                </v-row>

            </v-col>

            <v-col
                cols="auto"
                v-if="$vuetify.breakpoint.smAndDown"
            >
                <layout-toggle :option="layoutOption" />
            </v-col>

            <v-col
                cols="6"
                md="5"
                lg="4"
                xl="3"
                class="text-center"
                v-if="!isMobile"
            >
                <v-btn
                    large
                    @click="print"
                    color="primary"
                    v-if="$route.meta.printable"
                >
                    Print View
                    <v-icon right> mdi-printer </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            class="my-6"
            justify="center"
        >
            <v-col
                cols="12"
                md="5"
                class="d-print-none"
            >
                <field
                    :option="zipOption"
                    class="animate__animated animate__zoomIn"
                />
            </v-col>

            <v-col
                class="text-center d-print-none"
                cols="12"
                md="5"
            >
                <field
                    :option="packageOption"
                    class="animate__animated animate__zoomIn"
                />
            </v-col>
            <v-col
                class="text-center"
                cols="12"
            >
                <timezone-declaration />
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col
                cols="12"
                md="8"
                class="d-print-none"
            >
                <main-bulletin />
            </v-col>
        </v-row>
        <games
            :games="games"
            :key="'season'"
            class="pt-0 pt-md-10"
        ></games>
    </div>
</template>


<script>
import DtvAds from "../components/shared/DtvAds";
import Games from "../components/Home/Games";
import Loading from "../components/shared/Loading";
import SportBar from "../components/Home/Filters/SportBar";
import LogoBar from "../components/Home/LogoBar";
import LayoutToggle from "../components/Home/Filters/LayoutToggle";
import TimezoneDeclaration from "../components/shared/TimezoneDeclaration";
import MonthBar from "../components/Home/Filters/MonthBar.vue";
import Field from "../components/Home/Field.vue";
import MainBulletin from "../components/Home/Bulletin/MainBulletin";


import {
    GET_MONTHS,
    GET_OPTIONS,
    GET_SEASON_GAMES,
    GET_SELECTED_OPTIONS,
} from "../store/actions.type";


export default {
    name: "Season",

    components: {
        DtvAds,
        SportBar,
        LogoBar,
        Games,
        Loading,
        MonthBar,
        LayoutToggle,
        TimezoneDeclaration,
        Field,
        MainBulletin,
    },
    data() {
        return {
            loading: true,
            selectedDays: [],
            dateMenu: false,
        };
    },
    computed: {
        games() {
            let startDay = moment(this.selectedDays[0]);
            let endDay = this.selectedDays.length > 1 ? moment(this.selectedDays[1]).endOf('day') : moment(this.selectedDays[0]).endOf('day');
            return this.$store.getters.getSortedFilteredGames.filter((game) => {
                const startsAt = moment.utc(game.starts_at).tz(this.timezone);
                return startsAt.isSameOrAfter(startDay)
                    &&
                    startsAt.isSameOrBefore(endDay)
            });
        },

        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezoneString");
        },
        months() {
            return this.$store.getters.getCurrentMonths.months;
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
        selectedMonth() {
            return this.$store.getters.getSingleOption("months");
        },
        layoutOption() {
            return this.$store.getters.getSingleOption("layout");
        },
        packageOption() {
            return this.$store.getters.getSingleOption("bundles");
        },
        league() {
            return this.store.getters.getSortedFilteredGames[0].league;
        },
        teamNameDisplay() {
            if (!this.$route.params.team) {
                return "";
            }
            //Get team name from parameter
            var team = this.$route.params.team;

            // Remove dashes and numbers
            team = team.replace(/-/g, " ").replace(/\d+$/, "");

            //Force Title Case
            team = this.toTitleCase(team);

            return team;
        },
        startDay() {
            let startDay = null;
            //Starting Day
            if (this.$route.params.month && this.$route.params.year) {
                const month = this.$route.params.month;
                const year = this.$route.params.year;
                startDay = moment([year, month - 1]).startOf("month");
            } else if (this.$route.params.league == "nfl" && this.hasTeam) {
                startDay = moment().startOf("month");
            } else {
                startDay = moment().startOf("day");
            }
            return startDay;
        },
        maxDay() {
            if (this.$route.params.month && this.$route.params.year) {
                const month = this.$route.params.month;
                const year = this.$route.params.year;
                return moment([year, month - 1]).endOf("month");
            } else {
                return moment().endOf("month");
            }
        },
        endDay() {
            //Ending Day
            const weekLater = this.startDay.clone().add(7, "d");
            const endDay = weekLater.isAfter(this.maxDay)
                ? this.maxDay.clone()
                : weekLater.clone();
            return endDay.endOf('day');
        },

    },
    methods: {
        print() {
            window.print();
        },
        setStartingSelectedDays() {
            let selectedDays = [];

            selectedDays.push(this.startDay.format("YYYY-MM-DD"));

            if (this.$route.params.month && this.$route.params.year) {
                selectedDays.push(this.maxDay.format("YYYY-MM-DD"));
            } else if (this.$route.params.league == "NFL" && this.hasTeam) {
                selectedDays.push(this.maxDay.format("YYYY-MM-DD"));
            } else if (this.$route.params.league == "WNBA") {
                selectedDays.push(this.maxDay.format("YYYY-MM-DD"));
            } else {
                selectedDays.push(this.endDay.format("YYYY-MM-DD"));
            }

            this.selectedDays = selectedDays;
        },
    },
    mounted() {
        this.setStartingSelectedDays();

        // this.$gtag.event("api", {
        //     event_category: "options",
        //     event_label: "get_options",
        // });

        this.$store
            .dispatch(GET_OPTIONS)
            .then(() => {
                // this.$gtag.event("api", {
                //     event_category: "options",
                //     event_label: "get_selected_options",
                // });
                this.$store.dispatch(GET_SELECTED_OPTIONS);
                // this.$gtag.event("api", {
                //     event_category: "options",
                //     event_label: "get_season_months",
                // });
                this.$store.dispatch(GET_MONTHS, {
                    league_text_id: this.$route.params.league,
                });
            })
            .then(() => {
                // this.$gtag.event("api", {
                //     event_category: "events",
                //     event_label: "get_season_events",
                // });

                this.$store
                    .dispatch(GET_SEASON_GAMES, {
                        league_text_id: this.$route.params.league,
                        team_text_id: this.$route.params.team,
                        year: this.$route.params.year,
                        month: this.$route.params.month,
                    })
                    .then(() => {
                        this.loading = false;
                        //If no games for the selected dates, and no parameters chosen, set the end date to the end of the month
                        if (this.games.length === 0 && !this.$route.params.month && !this.$route.params.year) {
                            console.log("No games for selected dates, setting selected days to first day of month");
                            let selectedDays = [];
                            selectedDays.push(this.startDay.format("YYYY-MM-DD"));
                            selectedDays.push(moment().endOf("month").format("YYYY-MM-DD"));
                            this.selectedDays = selectedDays;
                        };
                    })
                    .catch((e) => {
                        this.loading = false;
                    });
            });
    },
    watch: {
        selectedDays(newValue, oldValue) {
            if (newValue.length > 1) {
                if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
                    this.selectedDays.reverse();
                }
            }
        }
    }
};
</script>


<style scoped>
::v-deep .v-date-picker-header {
    display: none;
}

.v-btn.v-size--large {
    font-size: 1.2rem;
}
</style>
