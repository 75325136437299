<template>
    <div>
        <div class="font-weight-bold caption">
            {{ channel.title_display }}
            <template v-if="channel.number_display !=
                channel.callsign_display
                ">
                -
                {{ channel.number_display }}
            </template>
        </div>
        <div
            class="caption"
            v-html="channelHtml"
        />
        <div
            class="caption"
            v-if="packages &&
                packages.length
                "
        >
            <strong>INCLUDED IN THESE TV PACKAGES:</strong>
            <br />
            <v-tooltip
                top
                v-for="(pack, pack_index) in packages"
                :key="pack_index"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        class="mr-1 mb-1"
                        :class="pack.grandfathered ? 'action-link' : ''"
                        :dark="pack.tag_color != 'yellow'"
                        :color="pack.tag_color"
                        :href="pack.grandfathered ? null : pack.promo_url"
                        target="_blank"
                        v-bind="attrs"
                        v-on="pack.grandfathered ? on : null"
                        small
                    >
                        <span
                            class="my-auto"
                            style="font-family:Arial, Helvetica, sans-serif"
                        >
                            {{ pack.tag_title }}
                        </span>
                        <v-icon
                            right
                            color="error"
                            size="small"
                            v-if="pack.grandfathered"
                        >
                            mdi-close-circle
                        </v-icon>
                    </v-chip>
                </template>
                <span>
                    {{ pack.grandfathered_message ? pack.grandfathered_message : 'This package is no longer available.' }}
                </span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChannelDetails",
    props: {
        channel: Object,
    },
    computed: {
        bundle() {
            return this.$store.getters.getCurrentSelectedOptions("bundles");
        },
        channelHtml() {
            let html = "";

            html += this.channel.nondtv ? "Not available on DIRECTV. " : "";
            html += this.channel.description;

            return html;
        },
        packages() {
            return this.channel.packages.filter(c => {
                if (this.bundle < 20) {
                    return c.residential == 1;
                }
                return c.commercial == 1;
            });
        },
    }
};
</script>
