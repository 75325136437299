<template>
    <div>
        <v-row class="ma-2 text-caption d-print-none" v-if="gameBulletins.length">
            <v-col cols="12" v-for="bulletin in gameBulletins" :key="bulletin.id">
                <div>{{  bulletin.body }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
  name: "GameBulletins",
  props: {
    game: Object,
  },
  computed: {
    bulletins() {
      return this.$store.getters.getBulletins;
    },
    gameBulletins() {
        let gameSpecificBulletins = this.game.bulletins;



        let gameGeneralBulletins = this.bulletins.filter((bulletin) => {

            //If game has a matching channel_number_major, or league
            let hasMatchingChannel = false;
            let hasMatchingLeague = false;
            let hasMatchingTeam = false;

            //First get an array of all channel number major in game. The channel is in the showings array
            let channelNumbers = this.game.showings.map((showing) => {
                return showing.channel.number_major;
            });

            if (bulletin.channel_number_major == null && bulletin.league_id == null && bulletin.team_id == null) {
                return false;
            }

            // console.log("bulletin.channel_number_major: " + bulletin.channel_number_major);
            // console.log("channelNumbers: " + channelNumbers);

            if (bulletin.channel_number_major == null || channelNumbers.includes(bulletin.channel_number_major)) {
                hasMatchingChannel = true;
            }

            // console.log("bulletin.league_id: " + bulletin.league_id);
            // console.log("this.game.league_id: " + this.game.league_id);

            if (bulletin.league_id == null || bulletin.league_id == this.game.league_id) {
                hasMatchingLeague = true;
            }

            // console.log("bulletin.team_id: " + bulletin.team_id);
            // console.log("this.game.home_team_id: " + this.game.home_team_id);
            // console.log("this.game.away_team_id: " + this.game.away_team_id);

            if (bulletin.team_id == null || (bulletin.team_id == this.game.home_team_id || bulletin.team_id == this.game.away_team_id)) {
                hasMatchingTeam = true;
            }

            // console.log("hasMatchingChannel: " + hasMatchingChannel);
            // console.log("hasMatchingLeague: " + hasMatchingLeague);
            // console.log("hasMatchingTeam: " + hasMatchingTeam);

            return hasMatchingChannel && hasMatchingLeague && hasMatchingTeam;

        });

        return gameSpecificBulletins.concat(gameGeneralBulletins);

    }
  },
};
</script>
