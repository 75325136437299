<template>
    <v-row no-gutters>
        <v-col
            cols="12"
            class="text-center"
            v-if="hasWinProb"
        >
            <h6>WIN PROBABILITY</h6>
            <v-row
                no-gutters
                justify="center"
                align="center"
            >
                <v-col
                    v-if="logoSource"
                    cols="auto"
                    class="mr-1"
                >
                    <v-img
                        v-if="winProbAway != winProbHome"
                        :src="logoSource"
                        width="32"
                        height="32"
                        contain
                        content-class="contain-paint team-logo"
                        transition="none"
                        :alt="logoAlt"
                    />
                </v-col>
                <v-col cols="auto">
                    <h3 class="font-weight-bold">{{ hasWinProb ? winProb : '-' }}</h3>
                </v-col>
            </v-row>
        </v-col>
        <v-col
            cols="12"
            class="text-center"
            v-else
        >
            <div style="height:48px"></div>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "WinProbability",
    props: {
        game: {
            type: Object,
            required: true,
        },
    },
    computed: {
        winProbAway() {
            return Math.round(this.game.win_prob_away * 100);
        },
        winProbHome() {
            return Math.round(this.game.win_prob_home * 100);
        },
        hasWinProb() {
            return !!this.game.win_prob_away && !!this.game.win_prob_home;
        },
        hasThuuz() {
            return !!this.game.thuuz_game;
        },
        winProb() {
            if (!this.hasWinProb) return null;

            if (this.winProbAway > this.winProbHome) {
                return this.winProbAway + "%";
            }
            if (this.winProbAway < this.winProbHome) {
                return this.winProbHome + "%";
            }
            return "50%";
        },
        logoSource() {
            if (this.winProbAway > this.winProbHome) {
                var logo = this.game.away_team.master_team_id
                    ? this.game.away_team.master_team_id
                    : this.game.away_team_id;
                return this.appUrl + "/img/logos/teams/" + logo + ".svg";
            }
            if (this.winProbAway < this.winProbHome) {
                var logo = this.game.home_team.master_team_id
                    ? this.game.home_team.master_team_id
                    : this.game.home_team_id;
                return this.appUrl + "/img/logos/teams/" + logo + ".svg";
            }
            return null;
        },
        logoAlt() {
            if (this.winProbAway > this.winProbHome) {
                return this.game.away_team.name + " Logo";
            }
            if (this.winProbAway < this.winProbHome) {
                return this.game.home_team.name + " Logo";
            }
            return null;
        },
    }
}
</script>

<style scoped>
.win-probability-title {
    font-size: .875rem !important;
}
</style>
