<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <v-container>
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                        md="2"
                        v-for="team in teams"
                        :key="team.id"
                    >
                        <v-card
                            :href="appUrl + '/leagues/' + team.league.slug.toUpperCase() + '/teams/' + team.text_id.toUpperCase() + '/schedule'"
                        >
                            <v-card-text>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="text-center"
                                    >
                                        <a>
                                            <v-img
                                                v-if="!isNfl && team.logo_exists"
                                                content-class="contain-paint"
                                                class="mx-auto d-print-none mb-2"
                                                transition="none"
                                                contain
                                                max-width="81"
                                                height="54"
                                                :src="logoSource(team)"
                                                :alt="team.display_name_short + ' Logo'"
                                            />
                                            <div class="text-uppercase font-weight-bold team-name my-3">
                                                {{ team.display_name_short }}
                                            </div>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>
import { GET_TEAMS_FOR_LEAGUE } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "Teams",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        teams() {
            return this.$store.getters.getCurrentTeams;
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        isNfl() {
            if (this.$route.params.league == "NFL") {
                return true;
            }
            return false;
        },
    },
    methods: {
        logoSource(team) {
            var logo = team.master_team_id
                ? team.master_team_id
                : team.id;
            return this.appUrl + "/img/logos/teams/" + logo + ".svg";

        },
    },
    mounted() {
        this.$store.dispatch(GET_TEAMS_FOR_LEAGUE, this.$route.params.league).then(() => {
            this.loading = false;
        });
    },
};
</script>

<style scoped>
.team-name {
    letter-spacing: 0.03em;
    color: black;
}

.text-uppercase {
    font-size: 1.2em;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: black;
}

a:active {
    text-decoration: none;
    color: black;
}
</style>
