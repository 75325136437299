<template>
  <v-col cols="auto">
    <v-card elevation="0" rounded="lg" class="ma-2" width="374">
      <v-row no-gutters class="pa-4">
        <v-col cols="5">
          <div class="text-center">
            <v-avatar size="80" class="channel-logo-shadow mx-5">
              <v-img
                width="30"
                height="30"
                contain
                class="channel-image"
                @error="onImgError"
                placeholder="https://www.directv.com/img/ent/global/missing_channel_logo.png"
                :src="channelLogo"
                :alt="channel.title_display + ' Logo'"
              />
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="7">
          <h2 class="jet">{{ channel.title_display }}</h2>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-4 pb-4">
        <v-col cols="5">
          <div class="mt-3 jet">{{ channel.callsign_display }}</div>
          <div class="jet">Channel {{ channel.number_display }}</div>
        </v-col>
        <v-col cols="7">
          <template v-for="rsn_channel in channel.rsn_channels">
            <div
              v-for="team in rsn_channel.teams"
              :key="team.id"
              class="mt-1 team-name jet"
            >
              <v-icon size="16" class="check-mark-color">mdi-check</v-icon>
              {{ team.display_name_full }}
            </div>
          </template>
        </v-col>
        <v-col cols="12" class="mt-4"><v-divider /></v-col>
        <v-col cols="12" class="mt-4 text--body-2 jet">
          Available on Satellite:
        </v-col>
        <v-col cols="12" class="mt-1">
          <v-row no-gutters>
            <template v-if="channel.rsn_channels[0].choice">
              <v-col cols="5" class="jet">
                English packages:
              </v-col>
              <v-col cols="7" class="jet">
                CHOICE&trade; and above
              </v-col>
              <v-col cols="5" class="jet">
                Español packages:
              </v-col>
              <v-col cols="7" class="jet">
                MAS ULTRA and above
              </v-col>
            </template>
            <template v-if="channel.rsn_channels[0].sports_pack">
              <v-col cols="5" class="jet">
                English packages:
              </v-col>
              <v-col cols="7" class="jet">
                SPORTS PACK and above
              </v-col>
              <v-col cols="5" class="jet">
                Español packages:
              </v-col>
              <v-col cols="7" class="jet">
                LO MAXIMO and above
              </v-col>
            </template>
            <template v-if="channel.rsn_channels[0].ultimate">
              <v-col cols="5" class="jet">
                Packages:
              </v-col>
              <v-col cols="7" class="jet">
                ULTIMATE and above
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "RsnChannel",
  props: { channel: Object },
  data() {
    return {
      failed_image: false,
    };
  },
  computed: {
    channelLogo() {
      if (this.failed_image) return "https://www.directv.com/img/ent/global/missing_channel_logo.png";

      if (Number.isInteger(this.channel.logo)) {
        return `https://www.directv.com/dtvassets/dtv/teamsite/images/logos/channels/dark/medium/${this.channel.logo}.png`;
      } else {
        return `${this.appUrl}/img/logos/channels/${this.channel.logo}.svg`;
      }
    },
  },
  methods: {
    onImgError(event) {
      console.log("image error");
      this.failed_image = true;
    },
  },
};
</script>

<style scoped>
.channel-logo-shadow {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1),
    0 24px 38px 3px rgba(0, 0, 0, 0), 0 9px 46px 8px rgba(0, 0, 0, 0.05) !important;
}
.team-name {
  font-size: 14px;
}
.jet {
  font-family: "Roboto", sans-serif !important;
  color: #102641;
}
.check-mark-color {
  color: #2e71bc;
}

.channel-image {
    max-width: 70% !important;
    border-radius: 0 !important;
}
</style>
