<template>
  <v-switch 
    inset 
    :label="option.name" 
    :loading="loading" 
    v-model="option.selectedOptions"
  />
</template>

<script>
export default {
  name: "Toggle",
  props: {
    option: Object
  },
  data() {
    return {
      loading: false
    }
  },
};
</script>