<template>
  <v-select
    v-model="option.selectedOptions"
    class="input-field"
    hide-details="auto"
    :label="option.name"
    :items="options"
    outlined
    :multiple="option.multiple"
  >
  </v-select>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    option: Object
  },
  computed: {
    options() {
      return this.$store.getters.getOptionList(this.option.key);
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined>>>.v-select__selection {
    color: #196DD4;
}

.v-text-field--outlined>>>.v-label {
    color: #196DD4;
    font-weight: bold;
}

.v-text-field--outlined>>>fieldset {
    border-color: #196DD4;
}

</style>
