<template>
    <v-row
        no-gutters
        align="center"
        justify="center"
        class="mr-3"
    >
        <v-col
            class="win-probability-title text-center"
            :class="$vuetify.breakpoint.sm && $vuetify.breakpoint.width <= 800 ? '' : 'mr-1'"
            :cols="$vuetify.breakpoint.sm && $vuetify.breakpoint.width <= 800 ? 12 : 'auto'"
        >
            WIN PROBABILITY
        </v-col>
        <v-col
            v-if="logoSource"
            cols="auto"
        >
            <v-img
                class="mx-auto d-print-none  contain-paint"
                contain
                height="39"
                max-width="50"
                v-if="winProbAway != winProbHome"
                :src="logoSource"
                :alt="logoAlt"
            />
        </v-col>
        <v-col
            class="text-center"
            cols="auto"
        >
            <v-avatar
                :color="hasWinProb ? 'primary' : 'gray'"
                :size="39"
                dark
            >
                <span
                    class="white--text"
                    :style="'letter-spacing:0px;font-size:15px'"
                >
                    {{ hasWinProb ? winProb : '-' }}
                </span>
            </v-avatar>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "CompactWinProbability",
    props: {
        game: {
            type: Object,
            required: true,
        },
    },
    computed: {
        winProbAway() {
            return Math.round(this.game.win_prob_away * 100);
        },
        winProbHome() {
            return Math.round(this.game.win_prob_home * 100);
        },
        hasWinProb() {
            return !!this.game.win_prob_away && !!this.game.win_prob_home;
        },
        hasThuuz() {
            return !!this.game.thuuz_game;
        },
        winProb() {
            if (!this.hasWinProb) return null;

            if (this.winProbAway > this.winProbHome) {
                return this.winProbAway + "%";
            }
            if (this.winProbAway < this.winProbHome) {
                return this.winProbHome + "%";
            }
            return "50%";
        },
        logoSource() {
            if (this.winProbAway > this.winProbHome) {
                var logo = this.game.away_team.master_team_id
                    ? this.game.away_team.master_team_id
                    : this.game.away_team_id;
                return this.appUrl + "/img/logos/teams/" + logo + ".svg";
            }
            if (this.winProbAway < this.winProbHome) {
                var logo = this.game.home_team.master_team_id
                    ? this.game.home_team.master_team_id
                    : this.game.home_team_id;
                return this.appUrl + "/img/logos/teams/" + logo + ".svg";
            }
            return null;
        },
        logoAlt() {
            if (this.winProbAway > this.winProbHome) {
                return this.game.away_team.name + " Logo";
            }
            if (this.winProbAway < this.winProbHome) {
                return this.game.home_team.name + " Logo";
            }
            return null;
        },
    }
}
</script>

<style scoped>
.win-probability-title {
    font-size: .875rem !important;
}
</style>
