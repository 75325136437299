<template>
    <v-row
        no-gutters
        justify="center"
        align="center"
    >
        <v-col
            cols="12"
            md="4"
            class="d-print-none py-4 py-md-0"
        >
            <span class="text-h6">
                All times are shown in
                <a @click="zipDialog = true">
                    {{ timezone }}
                </a>
                time
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="d-none d-print-block pa-0"
        >
            <span class="caption">
                All times are shown in
                {{ timezone }}
                time
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-0"
        >
            <span class="d-print-none text-h6">
                Shop for:
                <a href="https://www.directv.com/sports/">Residential</a>
                or
                <a href="https://www.directv.com/forbusiness/sports/">Business TV</a>
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-4 py-md-0"
        >
            <span class="d-print-none text-h6">
                <a href="https://www.directv.com/forbusiness/nfl-sunday-ticket/">NFL SUNDAY TICKET for Business</a>
            </span>
        </v-col>
        <v-dialog
            v-model="zipDialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Zip Code
                </v-card-title>

                <v-card-text>
                    Enter your zip code to see times in your local timezone. Changes are automatically saved.

                    <Field
                        :option="zipOption"
                        class="mt-4"
                    />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="zipDialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Field from "../Home/Field";

export default {
    name: 'TimezoneDeclaration',
    components: {
        Field,
    },
    data() {
        return {
            zipDialog: false,
        }
    },
    computed: {
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezone");
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
    },
}
</script>
