<template>
    <!-- DENSE -->
    <v-row
        v-if="layout > 0"
        no-gutters
    >
        <v-col>
            <v-row
                no-gutters
                class="mx-1"
                align="center"
                justify="space-around"
            >
                <v-col>
                    <team :team="game.away_team" />
                </v-col>
                <v-col
                    cols="auto"
                    class="mx-3"
                    v-if="game.game_status_id > 1 &&
                        game.away_score !== null &&
                        game.home_score !== null
                    "
                >
                    <div class="matchup">
                        {{ game.away_score }} - {{ game.home_score }}
                    </div>
                </v-col>
                <v-col
                    cols="auto"
                    v-else
                >
                    <div class="matchup">@</div>
                </v-col>
                <v-col>
                    <team :team="game.home_team" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <!-- MOBILE -->
    <v-row
        v-else-if="isMobile"
        no-gutters
    >
        <v-col>
            <v-simple-table class="mx-5">
                <tr>
                    <td width="50">
                        <a :href="!isEmbed && game.league.show_season_schedule
                            ?
                            appUrl +
                            '/leagues/' +
                            game.away_team.league_slug.toUpperCase() +
                            '/teams/' +
                            game.away_team.text_id.toUpperCase() +
                            '/schedule'
                            : false
                            ">
                            <v-img
                                v-if="!isNfl && game.away_team.logo_exists"
                                class="d-print-none"
                                transition="none"
                                content-class="contain-paint"
                                contain
                                height="30"
                                max-width="45"
                                :src="appUrl + '/img/logos/teams/' + (game.away_team.master_team_id ? game.away_team.master_team_id : game.away_team.id) + '.svg'"
                                :alt="game.away_team.name + ' Logo'"
                            />
                        </a>
                    </td>
                    <td class="text-uppercase">
                        <template v-if="!isEmbed && game.league.show_season_schedule">
                            <a :href="appUrl + '/leagues/' +
                                game.away_team.league_slug.toUpperCase() +
                                '/teams/' +
                                game.away_team.text_id.toUpperCase() +
                                '/schedule'
                                ">
                                {{ game.away_team.display_name_short }}
                            </a>
                        </template>
                        <template v-else>
                            {{ game.away_team.display_name_short }}
                        </template>
                    </td>
                    <td>
                        <div v-if="game.game_status_id > 1 &&
                            game.away_score !== null &&
                            game.home_score !== null
                        ">
                            {{ game.away_score }}
                        </div>
                    </td>
                    <td>
                        <time-display
                            v-if="game.game_status_id == 1"
                            :game="game"
                        />
                        <div v-else>
                            {{
                                game.game_status_id != 4 && game.game_status_reason
                                    ? game.game_status_reason
                                    : game.game_status.name
                            }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td width="50">
                        <a :href="!isEmbed && game.league.show_season_schedule
                            ?
                            appUrl +
                            '/leagues/' +
                            game.home_team.league_slug.toUpperCase() +
                            '/teams/' +
                            game.home_team.text_id.toUpperCase() +
                            '/schedule'
                            : false
                            ">
                            <v-img
                                v-if="!isNfl &&  game.home_team.logo_exists"
                                class="d-print-none"
                                content-class="contain-paint"
                                transition="none"
                                contain
                                height="30"
                                max-width="45"
                                :src="appUrl + '/img/logos/teams/' + (game.home_team.master_team_id ? game.home_team.master_team_id : game.home_team.id) + '.svg'"
                                :alt="game.home_team.name + ' Logo'"
                            />
                        </a>
                    </td>
                    <td class="text-uppercase">
                        <template v-if="!isEmbed && game.league.show_season_schedule">
                            <a :href="appUrl + '/leagues/' +
                                game.home_team.league_slug.toUpperCase() +
                                '/teams/' +
                                game.home_team.text_id.toUpperCase() +
                                '/schedule'
                                ">
                                {{ game.home_team.display_name_short }}
                            </a>
                        </template>
                        <template v-else>
                            {{ game.home_team.display_name_short }}
                        </template>

                    </td>
                    <td>
                        <div v-if="game.game_status_id > 1 &&
                            game.away_score !== null &&
                            game.home_score !== null
                        ">
                            {{ game.home_score }}
                        </div>
                    </td>
                    <td>
                        <!--<time-display v-if="game.game_status_id > 1" :game="game" />-->
                    </td>
                </tr>
            </v-simple-table>
        </v-col>
    </v-row>
    <!-- DESKTOP -->
    <v-row
        v-else
        class="pt-2"
        align="center"
    >
        <v-col class="text-center">
            <team :team="game.away_team" />
        </v-col>
        <v-col cols="auto">
            <score
                :game="game"
                v-if="game.game_status_id > 1 &&
                    game.status_away_score !== null &&
                    game.status_home_score !== null
                "
            />
            <v-row v-else>
                <v-col class="text-center">
                    <div class="matchup">
                        @
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="text-center">
            <team :team="game.home_team" />
        </v-col>
    </v-row>
</template>

<script>
import Team from "./Teams/Team";
import Score from "./Teams/Score";
import TimeDisplay from "./Shared/TimeDisplay";

export default {
    name: "Teams",
    components: {
        Team,
        Score,
        TimeDisplay,
    },
    props: {
        game: Object,
    },
    computed: {
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        isNfl() {
            if ((this.game.league_id == 10)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped>
.text-uppercase {
    font-size: 1.2em;
}

.matchup {
    font-size: 1.5em;
    font-weight: 100;
}

a:link {
    text-decoration: none;
    color: black;
}

a:visited {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: black;
}

a:active {
    text-decoration: none;
    color: black;
}
</style>
