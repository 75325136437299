<template>
    <div :class="isMobile ? 'lightGray fill-height' : ''">
        <dtv-header v-if="!isFramed" />
        <template v-if="isAnyMenuOpen">
            <mobile-filters v-if="isFiltersMenuOpen" />
            <mobile-sports v-if="isSportsMenuOpen" />
        </template>
        <v-main
            class="no-print-padding"
            :class="mainClass"
        >
            <v-row
                no-gutters
                class=""
            >
                <v-col
                    cols="12"
                    class="text-center"
                >
                    <!-- Regular Title -->
                    <h1 class="d-print-none mt-10 text-h4 font-weight-bold">{{ mainTitle }}</h1>
                    <!-- Print Title -->
                    <div class="d-none d-print-block text-h4 font-weight-bold mt-10">{{ mainTitle }}</div>
                </v-col>
            </v-row>

            <template v-if="!isMobile">
                <sport-bar class="mb-4 d-print-none" />
                <filters
                    class="d-print-none"
                    v-if="!isMobile"
                ></filters>
            </template>
            <template v-else>
                <mobile-options class="mt-4 mb-2 d-print-none" />
            </template>

            <v-container
                :class="isMobile ? 'px-0' : ''"
                class="py-0"
                style="max-width: 1360px"
            >

                <date-bar />

                <v-row
                    no-gutters
                    v-if="!isMobile"
                    align="center"
                    justify="center"
                    class="d-print-none"
                >
                    <v-col
                        cols="6"
                        md="10"
                        class="text-center caption"
                    >
                        <dtv-ads class="my-4" />
                    </v-col>
                    <v-col
                        cols="6"
                        md="5"
                        lg="4"
                        class="text-center d-print-none"
                        v-if="isValidMarchMadnessDate"
                    >
                        <v-btn
                            x-large
                            color="primary"
                            @click="setMarchMadness"
                        >
                            <v-icon
                                class="mr-2"
                                color="white"
                                large
                            >mdi-basketball</v-icon>
                            March Madness
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    justify="center"
                    v-if="bulletin"
                >
                    <v-col
                        cols="12"
                        md="8"
                        class="d-print-none"
                    >
                        <main-bulletin />
                    </v-col>
                </v-row>


                <div :class="isMobile ? 'mt-5' : ''">

                    <v-row
                        no-gutters
                        align="center"
                        justify="space-around"
                        class="px-1 px-md-14 py-2 py-md-5 d-print-none"
                    >
                        <v-col
                            cols="8"
                            sm="6"
                            md="auto"
                        >
                            <small-dropdown :option="groupOption" />
                        </v-col>
                        <v-col
                            cols="auto"
                            class="mt-2 mt-sm-0 text-center"
                        >
                            <layout-toggle :option="layoutOption" />
                        </v-col>
                        <v-col
                            cols="11"
                            sm="4"
                            lg="auto"
                            class="mt-2 mt-md-0 mb-4 text-center"
                        >
                            <toggle :option="completedOption" />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="4"
                            lg="auto"
                            :class="isMobile ? 'text-center' : 'text-right'"
                        >
                            <v-btn
                                @click="print"
                                :large="!isMobile"
                                color="primary"
                                class="font-weight-thin white--background"
                            >
                                {{ $vuetify.breakpoint.mdAndUp ? 'Print View' : 'Print' }}
                                <v-icon class="ml-2">mdi-printer</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        no-gutters
                        class="mt-2 d-print-none"
                        v-if="isMobile"
                    >
                        <v-col
                            cols="12"
                            md="10"
                            class="text-center caption"
                        >
                            <dtv-ads class="my-4" />
                        </v-col>
                        <v-col
                            cols="12"
                            class="mt-8 text-center  d-print-none"
                            v-if="isValidMarchMadnessDate"
                        >
                            <v-btn
                                x-large
                                color="primary"
                                @click="setMarchMadness"
                            >
                                <v-icon
                                    class="mr-2"
                                    color="white"
                                    large
                                >mdi-basketball</v-icon>
                                March Madness
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider
                        color="lightGrey"
                        v-if="!isMobile"
                    />
                    <v-col
                        v-if="isMobile"
                        cols="12"
                        class="text-center d-print-none pb-0"
                    >
                        <v-row no-gutters justify="center">
                            <v-col
                                cols="12"
                                sm="6"
                                class="text-center px-8"
                            >
                                <field
                                    :option="zipOption"
                                    class="animate__animated animate__zoomIn"
                                />
                            </v-col>
                        </v-row>

                    </v-col>
                    <v-col
                        cols="12"
                        class="text-center"
                    >
                        <timezone-declaration />
                    </v-col>
                    <games
                        :games="games"
                        :key="'home'"
                    ></games>
                </div>
            </v-container>
        </v-main>
        <v-dialog
            v-model="isLeaguesModalOpen"
            scrollable
            max-width="600"
        >
            <leagues-modal @done="doneLeaguesModal" />
        </v-dialog>

    </div>
</template>


<script>
import SportBar from "../components/Home/Filters/SportBar";
import DtvHeader from "../components/shared/DtvHeader";
import DateBar from "../components/Home/Filters/DateBar";
import Filters from "../components/Home/Filters";
import Tip from "../components/Home/Tip";
import Games from "../components/Home/Games";
import SmallDropdown from "../components/Home/Filters/SmallDropdown";
import LayoutToggle from "../components/Home/Filters/LayoutToggle";
import Toggle from "../components/Home/Filters/Toggle";
import MobileOptions from "../components/Home/MobileOptions";
import MobileFilters from "../components/Home/MobileFilters";
import MobileSports from "../components/Home/MobileSports";
import LeaguesModal from "../components/Home/Filters/LeaguesModal";
import SeoBar from "../components/Home/SeoBar";
import TimezoneDeclaration from "../components/shared/TimezoneDeclaration";
import DtvAds from "../components/shared/DtvAds";
import MainBulletin from "../components/Home/Bulletin/MainBulletin";
import Field from "../components/Home/Field.vue";

import {
    GET_OPTIONS,
    GET_SELECTED_OPTIONS,
    GET_GAMES,
    GET_ADDONS,
    POST_PARAM_OPTIONS,
    POST_SELECTED_OPTION,
} from "../store/actions.type";

import { CLOSE_LEAGUES_MODAL, SET_LEAGUES_MODEL, SET_SELECTED_ADDONS, SET_SELECTED_OPTIONS, SET_SPECIAL_FILTERS } from "../store/mutations.type";


export default {
    name: "Home",
    components: {
        DtvHeader,
        SportBar,
        DateBar,
        Filters,
        Tip,
        SmallDropdown,
        LayoutToggle,
        Toggle,
        Games,
        MobileOptions,
        MobileFilters,
        MobileSports,
        LeaguesModal,
        SeoBar,
        TimezoneDeclaration,
        DtvAds,
        MainBulletin,
        Field,
    },
    data() {
        return {
            loading: true,
            dialog: false,
            banner: true,
            marchMadness: false,
        };
    },
    computed: {
        mainTitle() {
            if (!this.$route.params.sport) return "Updating Events...";

            let new_title = "";

            if (this.$route.params.league && this.$route.params.league.length > 1) {

                //Custom Only
                if (
                    this.$route.params.sport && this.$route.params.sport.length > 1 && this.$route.params.sport == "custom"
                    ||
                    this.$route.params.league && this.$route.params.league.length > 1 && this.$route.params.league == "custom") {
                    new_title = "Custom Schedule";
                    return new_title;
                }

                new_title += ((!~['all', 'custom'].indexOf(this.$route.params.league)) ? this.$route.params.league.toUpperCase() : capitalizeFirstLetter(this.$route.params.league));
                new_title += " ";
            }

            if (this.$route.params.sport == "all") {
                new_title += " Games";
            } else {
                new_title += capitalizeFirstLetter(this.$route.params.sport);
            }


            return new_title;
        },
        bulletin() {
            return this.$store.getters.getMainBulletin;
        },
        games() {
            return this.$store.getters.getSortedFilteredGames;
        },
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezone");
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
        hasParams() {
            return this.$route.params.sport &&
                this.$route.params.league &&
                this.$route.params.date
                ? true
                : false;
        },
        groupOption() {
            return this.$store.getters.getSingleOption("groups");
        },
        layoutOption() {
            return this.$store.getters.getSingleOption("layout");
        },
        daysOption() {
            return this.$store.getters.getCurrentSelectedOptions("days");
        },
        completedOption() {
            return this.$store.getters.getSingleOption("completed");
        },
        isFiltersMenuOpen() {
            return this.$store.getters.isFiltersMenuOpen;
        },
        isSportsMenuOpen() {
            return this.$store.getters.isSportsMenuOpen;
        },
        isAnyMenuOpen() {
            return this.isFiltersMenuOpen || this.isSportsMenuOpen;
        },
        isLeaguesModalOpen: {
            get() {
                return this.$store.getters.isLeaguesModalOpen;
            },
            set(value) {
                console.log("setting leagues modal");
                if (this.$store.getters.getCurrentSelectedOptions("leagues").length == 0) {
                    this.$store.dispatch(POST_SELECTED_OPTION, {
                        key: "sports",
                        options: 0,
                    }).then(() => {
                        this.$store.commit(SET_LEAGUES_MODEL, value);
                    });
                } else {
                    this.$store.commit(SET_LEAGUES_MODEL, value);
                }
            },
        },
        mainClass() {
            let finalClass = "lightGray";
            if (this.isMobile) finalClass = finalClass + "lightGray";
            if (this.isAnyMenuOpen) finalClass = finalClass + " d-none";
            return finalClass;
        },
        addons() {
            //Addons ordered by title
            if (this.$store.getters.getAddons.length == 0) return [];

            let addons = this.$store.getters.getAddons.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });

            return addons;
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        isValidMarchMadnessDate() {
            let validDates = [
                "2024-03-17",
                "2024-03-19",
                "2024-03-20",
                "2024-03-21",
                "2024-03-22",
                "2024-03-23",
                "2024-03-24",
                "2024-03-25",
                "2024-03-26",
                "2024-03-27",
                "2024-03-28",
                "2024-03-29",
                "2024-03-30",
                "2024-03-31",
                "2024-04-01",
                "2024-04-02",
                "2024-04-03",
                "2024-04-04",
                "2024-04-05",
                "2024-04-06",
                "2024-04-07",
                "2024-04-08",
            ];
            console.log(this.daysOption);
            if (validDates.includes(this.daysOption)) {
                return true;
            }
            return false;
        },
    },
    methods: {
        print() {
            window.print();
        },
        doneLeaguesModal() {
            this.loading = false;
            console.log("done leagues modal");
            //If there are no selected leagues, set sports to all sports
            if (this.$store.getters.getCurrentSelectedOptions("leagues").length == 0) {
                this.$store.dispatch(POST_SELECTED_OPTION, {
                    key: "sports",
                    options: 0,
                }).then(() => {
                    this.$store.commit(CLOSE_LEAGUES_MODAL);
                });
            } else {
                this.$store.commit(CLOSE_LEAGUES_MODAL);
            }

        },
        setMarchMadness() {
            this.loading = true;
            this.$store.dispatch(POST_SELECTED_OPTION, {
                key: "sports",
                options: 99,
            }).then(() => {
                this.$store.dispatch(POST_SELECTED_OPTION, {
                    key: "leagues",
                    options: [61, 62],
                });
            }).then(() => {
                this.$store.commit(SET_SPECIAL_FILTERS, [{
                    key: "logo",
                    value: 'marchmadness',
                }]);
            });


        },
    },
    async mounted() {
        if (this.hasParams) {
            // this.$gtag.event("api", {
            //     event_category: "options",
            //     event_label: "set_options",
            // });

            //Set URL based parameters and wait until complete.
            await this.$store.dispatch(POST_PARAM_OPTIONS, this.$route.params);
        }

        // this.$gtag.event("api", {
        //     event_category: "options",
        //     event_label: "get_options",
        // });

        await this.$store
            .dispatch(GET_OPTIONS)
            .then(() => {
                // this.$gtag.event("api", {
                //     event_category: "options",
                //     event_label: "get_selected_options",
                // });
                this.$store.dispatch(GET_SELECTED_OPTIONS);
            })

            .then(() => {
                // this.$gtag.event("api", {
                //     event_category: "options",
                //     event_label: "get_events",
                // });
                this.$store
                    .dispatch(GET_GAMES)
                    .then(() => {
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.loading = false;
                    });
            });

        //ADDONS
        let selectedAddons = [];
        if (this.$route.query.addons) {
            let paramAddons = this.$route.query.addons.split(',');
            paramAddons.forEach((addon) => {
                selectedAddons.push(parseInt(addon));
            });

            this.$store.commit(SET_SELECTED_ADDONS, selectedAddons);
        }

        // this.$gtag.event("api", {
        //     event_category: "addons",
        //     event_label: "get_addons",
        // });
        await this.$store.dispatch(GET_ADDONS).then(() => {
            if (selectedAddons.length == 1) {
                //If here is only one addon, and its commercial setting differs from isCommercial, set the bundle to match
                if (this.addons[0].commercial != this.isCommercial) {
                    this.$store.dispatch(POST_SELECTED_OPTION, {
                        key: "bundles",
                        options: 20,
                    });
                }
            }

        });

    },
};
</script>

<style scoped>
@media print {
    .no-print-padding {
        padding: 0 !important;
    }
}
</style>
