<template>
    <v-row
        no-gutters
        justify="center"
        align="center"
    >
        <v-col
            v-if="!isCommercial"
            cols="12"
            md="4"
            class="py-4 py-md-0  text-h6 text-center"
        >
            <sportsbar />
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-0  text-center"
        >
            <span class="text-h6">
                Shop for:
                <a href="https://www.directv.com/sports/">Residential</a>
                or
                <a href="https://www.directv.com/forbusiness/sports/">Business TV</a>
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-4 py-md-0 text-center"
        >
            <span class="text-h6">
                <a href="https://www.directv.com/forbusiness/nfl-sunday-ticket/">NFL SUNDAY TICKET for Business</a>
            </span>
        </v-col>

    </v-row>
</template>

<script>
import Sportsbar from '../Home/Games/Game/Sportsbar';

export default {
    name: 'DtvAds',
    components: {
        Sportsbar,
    },
    computed: {
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
    },
}
</script>
